
import SidebarListItem from '~/components/general/AppSidebar/SidebarListItem'
import ALanguagePicker from '~/components/common/ALanguagePicker/index.vue'
import { SITE_LOGO_BLACK_SRC } from '~/constants/icon'

export default {
  name: 'SidebarContentDesktop',
  components: { ALanguagePicker, SidebarListItem },
  props: {
    sections: {
      type: Array,
      required: true
    }
  },
  consts: {
    SITE_LOGO_BLACK_SRC
  }
}
