
export default {
  name: 'AInput',
  props: {
    value: {
      type: [String, null],
      required: false,
      default: ''
    },
    inputId: {
      type: String,
      required: false,
      default: ''
    },
    rounded: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    inputDynamicClass() {
      return {
        'a-input--rounded': this.rounded
      }
    }
  },
  methods: {
    focus() {
      this.$refs.field.focus()
    }
  }
}
