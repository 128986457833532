
import Cookies from 'js-cookie'
import ASelect from '~/components/common/controls/ASelect/index.vue'
import { LOCALE } from '~/constants/locale'

const SELECTED_LOCALE_COOKIE_NAME = 'i18n_redirected'

const LANGUAGES = [
  {
    text: 'English',
    value: LOCALE.EN,
    logo: '/flags/us.png'
  },
  {
    text: 'Italiano',
    value: LOCALE.IT,
    logo: '/flags/it.png'
  },
  {
    text: 'Українська',
    value: LOCALE.UA,
    logo: '/flags/ua.png'
  },
  {
    text: 'Deutsch',
    value: LOCALE.DE,
    logo: '/flags/de.png'
  }
]

export default {
  name: 'ALanguagePicker',
  components: { ASelect },
  consts: {
    LANGUAGES
  },
  data() {
    return {
      selectedLanguage: this.$i18n.getLocaleCookie() || this.$i18n.locale
    }
  },
  watch: {
    '$i18n.locale': function() {
      // To make sure language is changed for all instances of this component
      this.selectedLanguage = this.$i18n.locale
    }
  },
  methods: {
    updateSelectedLocale(locale) {
      let newRoute = this.switchLocalePath(locale)

      const isRouteQueryExists = !!Object.keys(this.$route.query).length
      const isWindowSearchExists = !!window.location.search

      /**
       * Window history API is not reactive, so we have to consider it
       */
      if (isRouteQueryExists) {
        newRoute = newRoute.replace(/\?.*/, window.location.search || '')
      } else if (isWindowSearchExists) {
        newRoute = newRoute.concat(`?${window.location.search}`)
      }

      this.$router.push(newRoute)
      Cookies.set(SELECTED_LOCALE_COOKIE_NAME, locale)
    }
  }
}
