import { formatDescription } from '../utils/helpers/formatDescription'
import { FAMILY_NAME } from '~/constants/family'

function generateDietarySupplementAudiences({ familyValue, lectinFreeValue }) {
  const audiences = []

  if (!lectinFreeValue) {
    return JSON.stringify([
      {
        '@type': 'Audience',
        audienceType: 'None'
      }
    ])
  }

  audiences.push({
    '@type': 'Audience',
    audienceType: 'Lectin-Free'
  })

  if (
    [
      FAMILY_NAME.BEEF,
      FAMILY_NAME.GAME,
      FAMILY_NAME.PORK,
      FAMILY_NAME.POULTRY,
      FAMILY_NAME.SEAFOOD
    ].includes(familyValue)
  ) {
    audiences.push({
      '@type': 'Audience',
      audienceType: 'Carnivore'
    })
  }

  if (
    [
      FAMILY_NAME.FRUIT,
      FAMILY_NAME.OTHER_VEGETABLE,
      FAMILY_NAME.CRUCIFEROUS_VEGETABLES
    ].includes(familyValue)
  ) {
    audiences.push({
      '@type': 'Audience',
      audienceType: 'Vegan'
    })

    audiences.push({
      '@type': 'Audience',
      audienceType: 'Vegetarian'
    })
  }

  return JSON.stringify(audiences)
}

export const PAGE_SCHEME_TYPE = {
  ARTICLE: 'Article',
  WEB_SITE: 'WebSite',
  WEB_PAGE: 'WebPage',
  PRODUCT: 'Product',
  ITEM_LIST: 'ItemList',
  RECIPE: 'Recipe',
  ABOUT_PAGE: 'AboutPage',
  BOOK: 'Book',
  DIETARY_SUPPLEMENT: 'DietarySupplement'
}

export const PAGE_SCHEME = {
  [PAGE_SCHEME_TYPE.WEB_SITE]: ({
    headline = '',
    isBasedOnUrl = '',
    text = '',
    thumbnailUrl = '',
    description = ''
  }) =>
    `"@type": "WebSite",
	   "headline": "${headline}",
	   "isBasedOnUrl": "${isBasedOnUrl}",
	   "text": "${text}",
	   "thumbnailUrl": "${thumbnailUrl}",
	   "description": "${formatDescription(description)}"`,

  [PAGE_SCHEME_TYPE.ITEM_LIST]: ({ items, name = '', description = '' }) =>
    `"@type": "ItemList",
    "name": "${name}",
    "description": "${formatDescription(description)}",
    "itemListElement": [
      ${items
        .map(
          ({ url, name, imageUrl = '' }, index) => `{
        "@type": "ListItem",
        "position": ${index + 1},
        "name": "${name}",
        "image": "${imageUrl}",
        "url": "${url}"
      }`
        )
        .join(',\n')}
    ]`,
  [PAGE_SCHEME_TYPE.RECIPE]: ({
    name = '',
    url = '',
    datePublished = '',
    description = '',
    instructions = '',
    ingredients = '',
    imageUrl = '',
    prepTime = '',
    recipeCategory = ''
  }) =>
    `"@type": "Recipe",
    "name": "${name}",
    "image": "${imageUrl}",
    "author": {
      "@type": "Organization",
      "name": "No Lectin Food"
    },
    "datePublished": "${datePublished}",
    "description": "${formatDescription(description)}",
    "prepTime": "${prepTime}",
    "cookTime": "${prepTime}",
    "totalTime": "${prepTime}",
    "recipeCategory": "${recipeCategory}",
    "recipeCuisine": "Italian",
    "keywords": "lectin-free, gluten-free, ${recipeCategory.toLowerCase()}",
    "recipeIngredient": [
      ${ingredients.map(ingredient => `"${ingredient}"`).join(',\n')}
    ],
    "recipeInstructions": [${instructions
      .map(
        (instruction, index) =>
          `{
            "@type": "HowToStep",
            "name": "${index + 1}",
            "text": "${instruction}",
            "url": "${url}#step_${index + 1}"
          }`
      )
      .join(',')}]`,
  [PAGE_SCHEME_TYPE.ARTICLE]: ({
    headline = '',
    datePublished = '',
    articleBody = ''
  }) =>
    `"@type": "Article",
    "headline": "${headline}",
    "datePublished": "${datePublished}",
    "articleBody": "${articleBody}"`,
  [PAGE_SCHEME_TYPE.BOOK]: ({
    name = '',
    description = '',
    imageUrl = '',
    url = ''
  }) =>
    `"@type": "Book",
    "name": "${name}",
    "url": "${url}",
    "description": "${formatDescription(description)}",
    "image": "${imageUrl}"`,
  [PAGE_SCHEME_TYPE.PRODUCT]: ({
    name = '',
    description = '',
    imageUrl = '',
    url = ''
  }) =>
    `"@type": "Product",
    "name": "${name}",
    "offers": {
      "@type": "Offer",
      "url": "${url}"
    },
    "description": "${formatDescription(description)}",
    "image": "${imageUrl}"`,
  [PAGE_SCHEME_TYPE.ABOUT_PAGE]: ({ name = '', description = '' }) =>
    `"@type": "AboutPage",
    "name": "${name}",
    "description": "${formatDescription(description)}"`,
  [PAGE_SCHEME_TYPE.WEB_PAGE]: ({ name = '', description = '' }) =>
    `"@type": "WebPage",
    "name": "${name}",
    "description": "${formatDescription(description)}"`,
  [PAGE_SCHEME_TYPE.DIETARY_SUPPLEMENT]: ({
    name = '',
    description = '',
    imageUrl = '',
    precaution = '',
    family = '',
    familyValue = '',
    eatRaw = '',
    lectinFree = '',
    lectinFreeValue = '',
    probiotic = '',
    polyphenolsRich = '',
    energyBoosting = '',
    pressure = '',
    type = '',
    pageUrl = ''
  }) =>
    `"@type": "DietarySupplement",
    "name": "${name}",
    "description": "${formatDescription(description)}",${
      imageUrl ? `\n"image": "${imageUrl}",` : ''
    }
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "${pageUrl}"
    },
    "manufacturer": {
      "@type": "Organization",
      "name": "No Lectin Food"
    },
    "additionalProperty": [
      {
        "@type": "PropertyValue",
        "name": "Family",
        "value": "${family}"
      },
      {
        "@type": "PropertyValue",
        "name": "Raw Edibility",
        "value": "${eatRaw}"
      },
      {
        "@type": "PropertyValue",
        "name": "Lectin-Free",
        "value": "${lectinFree}"
      },
      {
        "@type": "PropertyValue",
        "name": "Probiotic",
        "value": "${probiotic}"
      },
      {
        "@type": "PropertyValue",
        "name": "Rich in Polyphenols",
        "value": "${polyphenolsRich}"
      },
      {
        "@type": "PropertyValue",
        "name": "Energy-Boosting",
        "value": "${energyBoosting}"
      },
      {
        "@type": "PropertyValue",
        "name": "Pressure",
        "value": "${pressure}"
      },
      {
        "@type": "PropertyValue",
        "name": "Type",
        "value": "${type}"
      }
    ],
    "audience": ${generateDietarySupplementAudiences({
      familyValue,
      lectinFreeValue
    })},
    "safetyConsideration": [
      "${precaution}"
    ]`
}

const escapeSchema = schema => {
  if (!schema) return ''

  return schema.replace(/\\/g, '')
}

function replaceDoubleQuotesBySingle(text) {
  if (!text || typeof text !== 'string') return text

  return text.replace(/"/g, "'")
}

function replaceDoubleQuotesInObjectValues(obj = {}) {
  return Object.entries(obj).reduce(
    (acc, [k, v]) => ({ ...acc, [k]: replaceDoubleQuotesBySingle(v) }),
    {}
  )
}

const drawSchemes = schemes =>
  `"@graph": [${schemes
    .map(
      ({ type, data }) =>
        `{${escapeSchema(
          PAGE_SCHEME[type](replaceDoubleQuotesInObjectValues(data) || {})
        )}}`
    )
    .join(',\n')}]`

export const generatePageSchema = schemes => {
  if (!schemes || !schemes.length) {
    /**
     * Removing schemas added by parent pages
     */
    return {
      script: [
        {
          hid: 'page-schemas',
          innerHTML: null
        }
      ]
    }
  }

  const schemeScript = `{
      "@context": "https://schema.org",
      ${drawSchemes(schemes)}
  }`

  return {
    script: [
      {
        hid: 'page-schemas',
        innerHTML: schemeScript,
        type: 'application/ld+json'
      }
    ]
  }
}
