
import getDefaultTextSettingsByValue from '~/components/common/AConditionalText/helpers/getTextSettingsByValue'

export default {
  name: 'AConditionalText',
  props: {
    value: {
      type: [String, Boolean, Number, null],
      required: false,
      default: ''
    },
    noStyle: {
      type: Boolean,
      required: false,
      default: false
    },

    /**
     * Depending on the boolean value, returns settings object
     * @param value {boolean}
     *
     * @returns
     * {
     *   // if returned, takes precedence over "value"
     *   i18nPath: 'path.to.translation',
     *
     *   value: 'text to display',
     *   color: 'red'
     * }
     */
    settingsFn: {
      type: Function,
      required: false,
      default: getDefaultTextSettingsByValue
    }
  },
  computed: {
    currentSettings() {
      return this.settingsFn(this.value)
    },
    displayValue() {
      const { value, i18nPath } = this.currentSettings

      return i18nPath ? this.$translateOrDefault(i18nPath) : value
    },
    color() {
      return this.currentSettings.color
    },
    dynamicStyles() {
      if (this.noStyle) return {}

      return {
        color: this.color
      }
    }
  }
}
