import AWS from 'aws-sdk'

export async function getCognitoCredentials() {
  const cognitoCredentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: process.env.AWS_COGNITO_IDENTITY_POOL_ID
  })

  const resolvedCredentials = await new Promise((resolve, reject) => {
    cognitoCredentials.get(err => {
      if (err) {
        console.log('Error fetching Cognito credentials: ', err)
        reject(err)
      } else {
        resolve(cognitoCredentials)
      }
    })
  })

  return resolvedCredentials
}
