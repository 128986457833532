import { ROUTE_NAME } from '~/constants/routeName'

/**
 * Application routes map
 * It is used to provide access to application pages via variables
 * to prevent syntax errors and give autocomplete in IDE
 * @param context
 * @param inject
 */
export default function appRoutesPlugin(context, inject) {
  const routesConfig = {
    home: {
      route: {
        name: ROUTE_NAME.INDEX
      }
    },
    recipe({ category, recipe }) {
      return {
        route: {
          name: ROUTE_NAME.RECIPE,
          params: {
            category,
            recipe
          }
        }
      }
    },
    recipes() {
      return {
        route: {
          name: ROUTE_NAME.RECIPES
        }
      }
    },
    category({ category }) {
      return {
        route: {
          name: ROUTE_NAME.CATEGORY,
          params: {
            category
          }
        }
      }
    },
    about: {
      route: {
        name: ROUTE_NAME.ABOUT
      }
    },
    privacy: {
      route: {
        name: ROUTE_NAME.PRIVACY
      }
    },
    termsOfService: {
      route: {
        name: ROUTE_NAME.TERMS_OF_SERVICE
      }
    },
    ingredients: {
      route: {
        name: ROUTE_NAME.INGREDIENTS
      }
    },
    ingredient({ ingredient }) {
      return {
        route: {
          name: ROUTE_NAME.INGREDIENT,
          params: {
            ingredient
          }
        }
      }
    },
    articles: {
      route: {
        name: ROUTE_NAME.ARTICLES
      }
    },
    article({ article }) {
      return {
        route: {
          name: ROUTE_NAME.ARTICLE,
          params: {
            article
          }
        }
      }
    },
    newsletter: {
      route: {
        name: ROUTE_NAME.NEWSLETTER
      }
    },
    newsletterUnsubscribe: {
      route: {
        name: ROUTE_NAME.NEWSLETTER_UNSUBSCRIBE
      }
    }
  }

  inject('routes', routesConfig)
}
