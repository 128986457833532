import { SORT_DIRECTION } from '~/constants/sortDirection'
import { CATEGORY_TITLE_BY_CATEGORY_SLUG } from '~/constants/category'
import { LOCALE, LOCALE_PREFIX_REGEXP } from '~/constants/locale'
import { DEFAULT_SCROLL_DURATION } from '~/constants/scroll'
import { UNIT } from '~/constants/unit'
import { removeEndingSlash } from '~/utils/helpers/removeEndingSlash'
import { generatePageSchema } from '~/constants/pageScheme'
import { PHOTO_EXTENSION, PHOTO_SIZE_SUFFIX_BY_SIZE } from '~/constants/photo'
import { formatDescription } from '../utils/helpers/formatDescription'

export function getParentElementWith(
  el,
  { className = null, attribute = null, tagName = null, id = null }
) {
  function getParent(el) {
    const targetEl = el.parentElement

    if (!targetEl) {
      return null
    }

    if (
      Array.from(targetEl.classList).includes(className) ||
      targetEl.tagName.toLowerCase() === tagName.toLowerCase() ||
      (targetEl.hasAttribute && targetEl.hasAttribute(attribute)) ||
      targetEl.id === id
    ) {
      return targetEl
    }

    return getParent(targetEl)
  }
  return getParent(el)
}

export function isObject(val) {
  if (!val) return false

  return typeof val === 'object' && val.constructor === Object
}

export function isArray(val) {
  if (!val) return false

  return Array.isArray(val)
}

function isEmpty(value) {
  if (isObject(value) || isArray(value)) return Object.keys(value).length === 0

  return value == null || value === ''
}

export function serializeParameters(params) {
  const searchParams = new URLSearchParams()

  Object.entries(params).forEach(([key, value]) => {
    if (isEmpty(value)) return

    if (Array.isArray(value)) {
      value.forEach(v => {
        searchParams.append(key, v)
      })
    } else {
      searchParams.append(key, value)
    }
  })

  return searchParams
}

export function formatToLocaleString(date) {
  if (!date) return ''

  return new Date(date).toLocaleString()
}

export function formatToLocaleDate(date) {
  if (!date) return ''

  return new Date(date).toLocaleDateString()
}

export function minutesToIsoDuration(minutes) {
  if (!minutes) return `PT0M`

  const hours = Math.floor(minutes / 60)
  const remainingMinutes = minutes % 60
  return `PT${hours > 0 ? `${hours}H` : ''}${
    remainingMinutes > 0 ? `${remainingMinutes}M` : ''
  }`
}

export function formatToIsoDate(date) {
  if (!date) return ''

  try {
    const dateFormatted = new Date(date).toISOString().split('T')[0]

    return dateFormatted
  } catch (err) {
    return ''
  }
}

const MINUTES_IN_HOUR = 60

export function minutesToReadableTime({ ctx, minutes }) {
  const { $translateOrDefault } = ctx

  if (typeof Number(minutes) !== 'number' || minutes <= 0) {
    return ''
  }

  const hours = Math.floor(minutes / MINUTES_IN_HOUR)
  const remainingMinutes = minutes % MINUTES_IN_HOUR

  let readableTime = ''

  if (hours > 0) {
    readableTime +=
      hours +
      (hours === 1
        ? ` ${$translateOrDefault('general.text_shared.hour')}`
        : ` ${$translateOrDefault('general.text_shared.hours')}`)
  }

  if (remainingMinutes > 0) {
    if (hours > 0) {
      readableTime += ' '
    }

    readableTime +=
      remainingMinutes +
      (remainingMinutes === 1
        ? ` ${$translateOrDefault('general.text_shared.minute')}`
        : ` ${$translateOrDefault('general.text_shared.minutes')}`)
  }

  return readableTime
}

const GRAMS_IN_KILOGRAM = 1000
const MILLIGRAMS_IN_GRAM = 1000

export const MILLIGRAM_MULTIPLIER_BY_UNIT = {
  [UNIT.KILOGRAM]: GRAMS_IN_KILOGRAM * MILLIGRAMS_IN_GRAM,
  [UNIT.GRAM]: MILLIGRAMS_IN_GRAM,
  [UNIT.MILLIGRAM]: 1,
  [UNIT.LITERS]: GRAMS_IN_KILOGRAM * MILLIGRAMS_IN_GRAM,
  [UNIT.MILLILITERS]: MILLIGRAMS_IN_GRAM
}

/**
 * Formats a number to remove unnecessary trailing zeros and appends the unit.
 * - If the number is an integer, no decimal part will be shown.
 * - If the number has one decimal place, it will be shown without trailing
 *   zeros.
 * - If the number has two decimal places, it will be shown with both decimal
 *   places, removing trailing zeros if any.
 *
 * Examples:
 * 3.00 -> 3
 * 3.11 -> 3.11
 * 3.01 -> 3.01
 * 3.10 -> 3.1
 *
 * @param {number} number - The number to format.
 * @param {number} fractionDigits
 * @returns {number} The formatted number
 */
export function formatNumber(number, fractionDigits = 3) {
  return Number(
    number
      .toFixed(fractionDigits)
      .replace(/\.0+$/, '')
      .replace(/(\.\d)0+$/, '$1')
  )
}

export function convertWeightToMilligrams(value, unit) {
  return value * MILLIGRAM_MULTIPLIER_BY_UNIT[unit] || 0
}

export function convertWeightToUnit(value, oldUnit, newUnit) {
  const valueInMilligrams = convertWeightToMilligrams(value, oldUnit)
  return valueInMilligrams / MILLIGRAM_MULTIPLIER_BY_UNIT[newUnit]
}

export function getWeightSumInGrams(weightItems) {
  const valueInMilligrams = weightItems.reduce((acc, { quantity, unit }) => {
    if (!unit || !quantity) return acc

    const multiplier = MILLIGRAM_MULTIPLIER_BY_UNIT[unit]
    acc += quantity * multiplier

    return acc
  }, 0)

  const valueInGrams = valueInMilligrams / MILLIGRAMS_IN_GRAM

  return {
    quantity: formatNumber(valueInGrams),
    valueInMilligrams
  }
}

export function capitalizeFirstLetter(str) {
  if (!str) return ''
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export function showPageNotFound(ctx) {
  ctx.$nuxt.error({
    statusCode: 404,
    message: 'This page could not be found'
  })
}

export function isString(val) {
  return typeof val === 'string' || val instanceof String
}

function endsWith(char, str = '') {
  if (!str || !isString(str)) return false

  return str[str.length - 1] === char
}

export function addEndingSlash(str = '') {
  if (!str) return ''

  return endsWith('/', str) ? str : `${str}/`
}

function removeArrayItemByIndex(arr, index) {
  return [...arr.slice(0, index), ...arr.slice(index + 1)]
}

function getObjValueByPath(obj, path) {
  const pathArr = path.split('.')
  const pathLength = pathArr.length

  for (let i = 0; i < pathLength; i++) {
    obj = obj?.[pathArr[i]]
  }

  return obj
}

function searchPhraseIncludesItemOrTranslation({
  searchPhrase = '',
  itemText = '',
  itemTextTranslation = ''
}) {
  return [itemText?.toLowerCase(), itemTextTranslation?.toLowerCase()].some(
    text => text && text.includes(searchPhrase?.toLowerCase())
  )
}

function sortAscending(prop) {
  return (a, b) => {
    if (getObjValueByPath(a, prop) < getObjValueByPath(b, prop)) {
      return -1
    }

    if (getObjValueByPath(a, prop) > getObjValueByPath(b, prop)) {
      return 1
    }

    return 0
  }
}

function sortDescending(prop) {
  return (a, b) => {
    if (getObjValueByPath(a, prop) < getObjValueByPath(b, prop)) {
      return 1
    }

    if (getObjValueByPath(a, prop) > getObjValueByPath(b, prop)) {
      return -1
    }

    return 0
  }
}

export function sortBy(prop, sortDirection) {
  return sortDirection === SORT_DIRECTION.ASC
    ? sortAscending(prop)
    : sortDescending(prop)
}

function getCategoryTitleFromSlugToi18n({ ctx, categorySlug }) {
  const categoryFormatted = CATEGORY_TITLE_BY_CATEGORY_SLUG[categorySlug]

  return ctx.$te(categoryFormatted)
    ? ctx.$t(categoryFormatted)
    : ctx.$helper.capitalizeFirstLetter(categoryFormatted)
}

export function getDefaultValueOrTranslation({
  ctx,
  defaultValue,
  translation
}) {
  return ctx.$i18n.locale === LOCALE.EN ? defaultValue : translation
}

export function getRouteNameWithoutLocalePrefix(routeName) {
  if (!routeName) return

  return routeName.replace(LOCALE_PREFIX_REGEXP, '')
}

function isRouteNameInList(routeName, routeNameList) {
  const routeNameFormatted = getRouteNameWithoutLocalePrefix(routeName)

  return routeNameList.includes(routeNameFormatted)
}

export function getPhotoFilePath(propertyId, propertyType) {
  if (!propertyId || !propertyType) return ''
  return `${propertyType}/${propertyId.replace('photo_id:', '')}`
}

export function getPhotoCdnUrl(path, size) {
  if (!path || !size) return null

  return [process.env.IMAGE_CDN_DOMAIN_URL, path]
    .map(removeEndingSlash)
    .join('/')
    .concat(`${PHOTO_SIZE_SUFFIX_BY_SIZE[size]}${PHOTO_EXTENSION.WEBP}`)
}

export function getLocaleUrl({ route, ctx }) {
  return `${removeEndingSlash(process.env.DOMAIN_URL)}${ctx.localePath(route)}`
}

export function generateAmazonAffiliateLink(searchPhrase) {
  if (!searchPhrase) return null

  return `https://www.amazon.com/s?k=${searchPhrase}&linkCode=ll2&tag=nolectinfood-20&linkId=2beb8cb82b4b880a2d46e9d74cfee52f&language=en_US&ref_=as_li_ss_tl`
}

const HEADER_SIZE_LG = 0
const HEADER_SIZE_SM = 0

export function getHeaderOffset(ctx) {
  if (ctx.$vuetify.breakpoint.xs) return 0

  return ctx.$vuetify.breakpoint.mdAndUp ? -HEADER_SIZE_LG : -HEADER_SIZE_SM
}

export function scrollTo({ ctx, selector }) {
  ctx.$scrollTo(selector, DEFAULT_SCROLL_DURATION, {
    offset: getHeaderOffset(ctx)
  })
}

export function omitKeys(keys, obj) {
  return Object.fromEntries(
    Object.entries(obj).filter(([key]) => !keys.includes(key))
  )
}

export function generatePageMeta({
  ctx,
  title,
  titleI18nPath,
  description,
  descriptionI18nPath,
  imageUrl,
  schemas = null,
  canonicalUrl = null,
  links = []
}) {
  const { $translateOrDefault } = ctx

  const pageTitle = title || $translateOrDefault(titleI18nPath)

  const pageDescription = formatDescription(
    description ||
      (descriptionI18nPath && $translateOrDefault(descriptionI18nPath)) ||
      ''
  )

  const pageSchema = generatePageSchema(schemas)

  const descriptionMeta = pageDescription
    ? [
        {
          hid: 'og:description',
          property: 'og:description',
          content: formatDescription(pageDescription)
        },
        {
          hid: 'meta-description',
          name: 'description',
          content: formatDescription(pageDescription)
        },
        {
          hid: 'twitter-description',
          name: 'twitter:description',
          content: formatDescription(pageDescription)
        }
      ]
    : []

  const imageMeta = imageUrl
    ? [
        {
          hid: 'og:image',
          property: 'og:image',
          content: imageUrl
        }
      ]
    : []

  const canonicalLink = canonicalUrl
    ? [
        {
          hid: 'i18n-can',
          rel: 'canonical',
          href: canonicalUrl
        }
      ]
    : []

  return {
    title: pageTitle,
    meta: [
      ...descriptionMeta,
      ...imageMeta,
      { hid: 'og:title', property: 'og:title', content: pageTitle },
      {
        hid: 'twitter-title',
        name: 'twitter:title',
        content: pageTitle
      }
    ],
    __dangerouslyDisableSanitizersByTagID: {
      'page-schemas': ['innerHTML']
    },
    script: [...pageSchema.script],
    link: [...canonicalLink, ...links]
  }
}

/**
 * https://stackoverflow.com/a/52855084/10780481
 * @returns {boolean}
 */
export function isTouchDevice() {
  return window.matchMedia('(pointer: coarse)').matches
}

export function isIngredientPhotoValid(photoId) {
  return !!photoId && photoId !== 'null'
}

export function getRandom(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

/**
 * Use for standard events: https://www.facebook.com/business/help/402791146561655
 */
export function facebookTrack(event, payload) {
  if (process.env.ENVIRONMENT === 'local' || typeof fbq === 'undefined') {
    return
  }

  window.fbq('track', event, payload)
}

/**
 * Use for custom events
 */
export function facebookTrackCustom(event, payload) {
  if (process.env.ENVIRONMENT === 'local' || typeof fbq === 'undefined') {
    return
  }

  window.fbq('trackCustom', event, payload)
}

export function selectAllTextInElement(el) {
  if (window.getSelection && document.createRange && el) {
    const range = document.createRange()
    range.selectNodeContents(el)

    const selection = window.getSelection()
    selection.removeAllRanges()
    selection.addRange(range)
  }
}

export function removeStartingSlash(str) {
  if (str.startsWith('/')) {
    return str.slice(1)
  }

  return str
}

export function buildFullUrl(path) {
  const domainUrlWithoutEndingSlash = removeEndingSlash(process.env.DOMAIN_URL)

  if (!path) return domainUrlWithoutEndingSlash

  const pathWithoutStartingSlash = removeStartingSlash(path)

  return `${domainUrlWithoutEndingSlash}/${pathWithoutStartingSlash}`
}

export function getRandomHexColor() {
  return `#${Math.floor(Math.random() * 0xffffff)
    .toString(16)
    .padStart(6, '0')}`
}

const helperMethods = {
  facebookTrack,
  facebookTrackCustom,
  getParentElementWith,
  capitalizeFirstLetter,
  formatToLocaleString,
  formatToLocaleDate,
  showPageNotFound,
  removeArrayItemByIndex,
  sortBy,
  getCategoryTitleFromSlugToi18n,
  getRouteNameWithoutLocalePrefix,
  serializeParameters,
  isEmpty,
  isArray,
  isString,
  isObject,
  addEndingSlash,
  removeEndingSlash,
  getDefaultValueOrTranslation,
  getPhotoFilePath,
  isRouteNameInList,
  getObjValueByPath,
  searchPhraseIncludesItemOrTranslation,
  minutesToReadableTime,
  generateAmazonAffiliateLink,
  getHeaderOffset,
  scrollTo,
  omitKeys,
  getWeightSumInGrams,
  generatePageMeta,
  isTouchDevice,
  formatNumber,
  convertWeightToMilligrams,
  convertWeightToUnit,
  getPhotoCdnUrl,
  getLocaleUrl,
  isIngredientPhotoValid,
  getRandom,
  selectAllTextInElement,
  minutesToIsoDuration,
  formatToIsoDate,
  removeStartingSlash,
  buildFullUrl,
  getRandomHexColor
}

export default function(_, inject) {
  inject('helper', helperMethods)
}
