/**
 * TODO Sync with breakpoint.scss file or find a way to import
 * breakpoints from scss file directly
 */
export const CSS_BREAKPOINT = {
  MOBILE: 600,
  TABLET: 960,
  LAPTOP: 1264,
  LAPTOP_MD: 1440,
  LAPTOP_LG: 1700,
  DESKTOP: 1904
}
