// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media(max-width:1439px){.app__wrapper__homepage .sidebar-footer__wrapper{display:flex}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"redWCAG": "#c90b0b",
	"orangeWCAG": "#b85700",
	"greenWCAG": "#088722",
	"red": "#dc583a",
	"olive": "#9d9c2e",
	"yellowMain": "#fecb3d",
	"darkYellow": "#f3b02c",
	"lightGray1": "#f8f8f8",
	"lightGray2": "#eee",
	"darkGray3": "#777",
	"white": "#fff"
};
module.exports = ___CSS_LOADER_EXPORT___;
