import AWS from 'aws-sdk'
import { getCognitoCredentials } from '~/utils/helpers/getCognitoCredentials'

const region = process.env.AWS_COGNITO_REGION || 'us-east-1'

AWS.config.update({
  region
})

let credentials = null
let dynamoDBClient = null

export default {
  methods: {
    async $_getClientInstance() {
      credentials = credentials || (await getCognitoCredentials())

      dynamoDBClient =
        dynamoDBClient ||
        new AWS.DynamoDB.DocumentClient({
          credentials
        })

      return dynamoDBClient
    },
    async $_dynamoClient_putItem({ item }) {
      const client = await this.$_getClientInstance()
      return client.put(item).promise()
    },
    async $_dynamoClient_deleteKey({ key }) {
      const client = await this.$_getClientInstance()
      return client.delete(key).promise()
    }
  }
}
