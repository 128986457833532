
import { Z_INDEX_VALUE } from '~/constants/zIndex'
import * as mixins from '~/mixins'

const MAX_FILTERS_TO_SHOW = 1

const DEFAULT_INPUT_ID = 'table-search'

export default {
  name: 'ATableFilters',
  mixins: [mixins.hydration],
  props: {
    filters: {
      type: Array,
      required: true
    },
    selectedFilters: {
      type: Array,
      required: true
    },
    search: {
      type: String,
      required: false,
      default: ''
    },
    inputId: {
      type: String,
      required: false,
      default: DEFAULT_INPUT_ID
    }
  },
  data() {
    return {
      isDropdownVisible: false
    }
  },
  computed: {
    wrapperDynamicStyle() {
      if (this.$vuetify.breakpoint.xsOnly && this.isDropdownVisible) {
        return {
          'z-index': Z_INDEX_VALUE.TABLE_FILTERS_DROPDOWN_MOBILE
        }
      }

      return {}
    },
    expandableIcon() {
      return this.isDropdownVisible ? '$collapse' : '$expand'
    },
    isMobileOrTabletLayout() {
      return this.isMounted && this.$vuetify.breakpoint.smAndDown
    },
    dropDownZIndex() {
      return this.isMobileOrTabletLayout
        ? Z_INDEX_VALUE.TABLE_FILTERS_DROPDOWN_MOBILE
        : Z_INDEX_VALUE.TABLE_FILTERS_DROPDOWN
    },
    isFilterSelectedByField() {
      return this.filters.reduce((acc, filter, index) => {
        acc[filter.field] = this.selectedFilters.includes(index)

        return acc
      }, {})
    },
    isNoFilterSelected() {
      return !this.selectedFilters.length
    },
    showingFiltersText() {
      if (this.isNoFilterSelected) {
        return this.$translateOrDefault('general.filters.all')
      }

      const filtersCount = this.selectedFilters.length

      if (filtersCount <= MAX_FILTERS_TO_SHOW) {
        return this.selectedFilters
          .map(filterIndex =>
            this.$translateOrDefault(this.filters[filterIndex].titleI18nPath)
          )
          .join(', ')
      }

      const selectedTextPart = this.$translateOrDefault(
        'general.filters.selected'
      )

      return `${filtersCount} ${selectedTextPart}`
    }
  },
  methods: {
    toggleDropdown() {
      this.isDropdownVisible = !this.isDropdownVisible
    },
    onUpdate(selection) {
      this.$emit('update:selectedFilters', selection)
    },
    resetFilters() {
      this.$emit('update:selectedFilters', [])
    }
  }
}
