
import SidebarListItem from '~/components/general/AppSidebar/SidebarListItem/index.vue'
import ALanguagePicker from '~/components/common/ALanguagePicker/index.vue'

export default {
  name: 'SidebarContentMobileRight',
  components: { ALanguagePicker, SidebarListItem },
  props: {
    secondaryItems: {
      type: Array,
      required: true
    }
  },
  computed: {
    secondaryItemsFormatted() {
      const ingredientsTitle = this.$translateOrDefault(
        'general.sidebar.ingredients'
      )

      return this.secondaryItems.filter(item => item.title !== ingredientsTitle)
    }
  },
  methods: {
    onClick(item) {
      this.$emit('close')

      if (typeof item.customClickHandler === 'function') {
        item.customClickHandler(this)
      }
    }
  }
}
