
import SidebarFooter from '../components/general/AppSidebar/SidebarFooter'
import AppSidebar from '../components/general/AppSidebar'
import AppNotifications from '~/components/general/AppNotifications'
import { CSS_BREAKPOINT } from '~/constants/cssBreakpoint'
import AppToolbarBottomMobile from '~/components/general/AppToolbarBottomMobile'
import SidebarContentDesktop from '~/components/general/AppSidebar/SidebarContentDesktop'
import { getCategoryNavItems } from '~/constants/category'
import SidebarContentMobileLeft from '~/components/general/AppSidebar/SidebarContentMobileLeft/index.vue'
import SidebarContentMobileRight from '~/components/general/AppSidebar/SidebarContentMobileRight/index.vue'
import * as mixins from '~/mixins'
import { ROUTE_NAME } from '~/constants/routeName'
import { HREFLANGS_BY_LOCALE, LOCALE } from '~/constants/locale'
import * as types from '~/constants/mutationTypes'
import { fetchAppVersion } from '~/utils/helpers/fetchAppVersion'
import {
  ABOUT_ICON_SRC,
  ARTICLES_ICON_SRC,
  INGREDIENTS_ICON_SRC,
  NEWSLETTER_ICON_SRC,
  RECIPES_ICON_SRC
} from '~/constants/icon'
import {
  HOTJAR_INLINE_SCRIPT,
  META_INLINE_SCRIPT
} from '~/constants/inlineScript'
import { Z_INDEX_VALUE } from '~/constants/zIndex'
import { DEFAULT_FAVICONS, FAVICONS_BY_ROUTE_NAME } from '~/constants/favicon'

export default {
  name: 'DefaultLayout',
  components: {
    SidebarContentMobileRight,
    SidebarContentMobileLeft,
    SidebarFooter,
    SidebarContentDesktop,
    AppToolbarBottomMobile,
    AppNotifications,
    AppSidebar
  },
  mixins: [mixins.scrollToElementOnInit],
  consts: {
    Z_INDEX_VALUE
  },
  data() {
    return {
      isMobileLeftSidebarOpen: false,
      isMobileRightSidebarOpen: false,
      categoryItems: getCategoryNavItems(this.$routes)
    }
  },
  head() {
    const routeNameWithoutPrefix = this.$helper.getRouteNameWithoutLocalePrefix(
      this.$route.name
    )

    const favIconLinks =
      FAVICONS_BY_ROUTE_NAME?.[routeNameWithoutPrefix]?.(this.$route.params) ||
      DEFAULT_FAVICONS()

    const { link: links } = this.$nuxtI18nHead({ addSeoAttributes: true })
    const canonicalLink = links.find(link => link.rel === 'canonical')
    const imageUrl = `${this.$helper.removeEndingSlash(
      process.env.DOMAIN_URL
    )}/no-lectin-food-social-sharing.png`

    const FACEBOOK_APP_ID = '477765934554442'

    const meta = [
      {
        hid: 'og:type',
        property: 'og:type',
        content: 'website'
      },
      {
        hid: 'og:url',
        property: 'og:url',
        content: canonicalLink.href
      },
      {
        hid: 'og:image',
        property: 'og:image',
        content: imageUrl
      },
      {
        hid: 'fb:app_id',
        property: 'fb:app_id',
        content: FACEBOOK_APP_ID
      }
    ]

    const prodScripts = [
      {
        innerHTML: HOTJAR_INLINE_SCRIPT
      },
      {
        innerHTML: META_INLINE_SCRIPT
      }
    ]

    return {
      htmlAttrs: {
        // Can't use LOCALE enum, because Ukrainian language code UK, not UA
        lang: HREFLANGS_BY_LOCALE[this.$i18n.locale][0]
      },
      link: [...links, ...favIconLinks],
      meta,
      script: [...(process.env.ENVIRONMENT === 'local' ? [] : prodScripts)],
      __dangerouslyDisableSanitizers: ['script']
    }
  },
  computed: {
    sidebarSections() {
      return [
        {
          items: [this.itemIngredients]
        },
        {
          items: [this.itemSidebarRecipes],
          className: 'sidebar-content__recipes'
        },
        {
          items: [
            this.itemArticles,
            ...(this.$i18n.locale === LOCALE.EN ? [this.itemNewsletter] : [])
          ]
        },
        {
          items: [this.itemAbout],
          className: 'sidebar-content__about'
        }
      ]
    },
    itemSidebarRecipes() {
      return {
        icon: RECIPES_ICON_SRC,
        i18nPath: 'general.sidebar.recipes',
        altText: 'Recipes icon',
        to: this.$routes.recipes().route,
        translate: true
      }
    },
    itemRecipes() {
      return {
        icon: RECIPES_ICON_SRC,
        i18nPath: 'general.mobile_bottom_toolbar.recipes',
        altText: 'Recipe icon',
        translate: true,
        customClickHandler: ctx => ctx.$emit('open-left-sidebar')
      }
    },
    itemIngredients() {
      return {
        icon: INGREDIENTS_ICON_SRC,
        i18nPath: 'general.sidebar.ingredients',
        altTextI18nPath: 'general.sidebar.alt_text.ingredients',
        to: this.$routes.ingredients.route,
        translate: true
      }
    },
    itemArticles() {
      return {
        icon: ARTICLES_ICON_SRC,
        i18nPath: 'general.sidebar.articles',
        altTextI18nPath: 'general.sidebar.alt_text.articles',
        to: this.$routes.articles.route,
        translate: true
      }
    },
    itemNewsletter() {
      return {
        icon: NEWSLETTER_ICON_SRC,
        i18nPath: 'general.sidebar.newsletter',
        altTextI18nPath: 'general.sidebar.alt_text.newsletter',
        to: this.$routes.newsletter.route,
        translate: true
      }
    },
    itemAbout() {
      return {
        icon: ABOUT_ICON_SRC,
        i18nPath: 'general.sidebar.about',
        altTextI18nPath: 'general.sidebar.alt_text.about',
        to: this.$routes.about.route,
        translate: true
      }
    },
    secondaryItems() {
      return [
        this.itemIngredients,
        this.itemRecipes,
        this.itemArticles,
        ...(this.$i18n.locale === LOCALE.EN ? [this.itemNewsletter] : []),
        this.itemAbout
      ]
    },
    isOverlayVisible() {
      return this.isMobileLeftSidebarOpen || this.isMobileRightSidebarOpen
    },
    appWrapperDynamicClass() {
      return {
        app__wrapper__homepage: this.$helper.isRouteNameInList(
          this.$route.name,
          [ROUTE_NAME.INDEX]
        ),
        [`app__wrapper__${this.$i18n.locale}`]: true
      }
    }
  },
  watch: {
    '$vuetify.breakpoint.width': {
      handler(value) {
        if (value >= CSS_BREAKPOINT.MOBILE) {
          this.isMobileLeftSidebarOpen = false
          this.isMobileRightSidebarOpen = false
        }
      }
    }
  },
  async mounted() {
    const version = await fetchAppVersion()

    this.$store.commit(types.SET_APP_VERSION, version)
  },
  methods: {
    closeAllSidebars() {
      this.closeLeftSidebar()
      this.closeRightSidebar()
    },
    closeRightSidebar() {
      this.isMobileRightSidebarOpen = false
    },
    closeLeftSidebar() {
      this.isMobileLeftSidebarOpen = false
    },
    onSidebarLeftToggle() {
      this.closeRightSidebar()
      this.isMobileLeftSidebarOpen = !this.isMobileLeftSidebarOpen
    },
    onSidebarRightToggle() {
      this.closeLeftSidebar()
      this.isMobileRightSidebarOpen = !this.isMobileRightSidebarOpen
    }
  }
}
