import { ROUTE_NAME } from '~/constants/routeName'

export const SEARCH_ID = {
  GLOBAL_SEARCH: 'global-search'
}

export const ROUTE_NAMES_TO_SHOW_GLOBAL_SEARCH_ON_MOBILE = [
  ROUTE_NAME.CATEGORY,
  ROUTE_NAME.INGREDIENTS
]
