
export default {
  name: 'PageContentContainer',
  props: {
    loading: {
      type: [Boolean, Number],
      required: false,
      default: false
    }
  }
}
