export const FAMILY_NAME = {
  BEEF: 'beef',
  BEVERAGES: 'beverages',
  CRUCIFEROUS_VEGETABLES: 'cruciferous_vegetables',
  DAIRY: 'dairy',
  FLOURS: 'flours',
  FRUIT: 'fruit',
  GAME: 'game',
  GRAINS: 'grains',
  LEAFY_GREENS: 'leafy_greens',
  LEGUMES: 'legumes',
  MUSHROOM: 'mushroom',
  NUTS: 'nuts',
  OIL: 'oil',
  OTHER: 'other',
  OTHER_VEGETABLE: 'other_vegetable',
  PORK: 'pork',
  POULTRY: 'poultry',
  PROCESSED_RESISTANT_STARCHES: 'processed_resistant_starches',
  REFINED_STARCHES: 'refined_starches',
  SEAFOOD: 'seafood',
  SEASONING: 'seasoning',
  SEEDS: 'seeds',
  SWEETENER: 'sweetener'
}
