export default {
  mounted() {
    const selector = this.$route.hash || this.$route.params.scrollTo

    if (selector) {
      setTimeout(() => {
        this.$helper.scrollTo({ ctx: this, selector })
      }, 0)
    }
  }
}
