import * as types from '~/constants/mutationTypes'

export const state = () => ({
  version: null,
  scrollTop: null
})

export const getters = {
  version: state => state.version,
  scrollTop: state => state.scrollTop
}

export const mutations = {
  [types.SET_APP_VERSION](state, value) {
    state.version = value
  },
  [types.SET_SCROLL_TOP](state, value) {
    state.scrollTop = value
  }
}
