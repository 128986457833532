import { fetchAppVersion } from '~/utils/helpers/fetchAppVersion'
import * as types from '~/constants/mutationTypes'

export default async function({ store }) {
  if (!process.client) return

  const { version } = store.getters

  fetchAppVersion().then(newVersion => {
    store.commit(types.SET_APP_VERSION, newVersion)

    if (version && version !== newVersion) {
      window.location.reload()
    }
  })
}
