export default {
  methods: {
    $_urlParams_castArrayOfStringsParamToBoolean(param) {
      if (!this.$helper.isArray(param)) {
        return this.$_urlParams_castStringParamToBoolean(param)
      }

      return param.map(this.$_urlParams_castStringParamToBoolean)
    },
    $_urlParams_castStringParamToBoolean(param) {
      if (param === 'false') return false

      if (param === 'true') return true

      return !!param
    },
    $_urlParams_updateUrlWithParams(params) {
      if (process.server) return

      const serializedParams = this.$helper.serializeParameters(params)
      const resultingQuery = serializedParams.size
        ? `?${serializedParams}`
        : window.location.pathname

      window.history.replaceState(null, null, resultingQuery)
    }
  }
}
