import { LOCALE } from '~/constants/locale'

export default {
  methods: {
    /**
     * Returns a translation for a current locale. If translation is not found,
     * the English translation is used as a fallback
     *
     * @param path {string}
     * @returns {string}
     */
    $translateOrDefault(path) {
      if (!path) return ''

      const { locale } = this.$i18n
      const englishTranslation = this.$t(path, LOCALE.EN)

      if (locale === LOCALE.EN) return englishTranslation

      const translation = this.$t(path, locale)
      const translationExists = this.$te(path, locale) && !!translation

      if (!translationExists) return englishTranslation

      return translation
    }
  }
}
