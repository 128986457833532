
import AHorizontalDelimiter from '~/components/common/AHorizontalDelimiter/index.vue'

export default {
  name: 'PageDescription',
  components: { AHorizontalDelimiter },
  props: {
    showDelimiter: {
      type: Boolean,
      required: false,
      default: true
    }
  }
}
