
import { marked } from 'marked'

export default {
  name: 'AMarkdownPreview',
  props: {
    markdown: {
      type: [String, null],
      required: false,
      default: null
    },
    rendererOptions: {
      type: Object,
      required: false,
      default: undefined
    }
  },
  computed: {
    customRenderer() {
      const customRenderer = new marked.Renderer()

      Object.keys(this.rendererOptions || {}).forEach(key => {
        customRenderer[key] = this.rendererOptions[key]
      })

      return customRenderer
    },
    markdownToHtml() {
      if (!this.markdown) return ''

      return marked(this.markdown, { renderer: this.customRenderer })
    }
  }
}
