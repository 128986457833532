
import color from '~/assets/styles/color.scss'

export default {
  name: 'ASearch',
  props: {
    value: {
      type: String,
      required: false,
      default: ''
    },
    inputId: {
      type: String,
      required: true
    },
    closeIconSmall: {
      type: Boolean,
      require: false,
      default: true
    },
    backgroundColor: {
      type: String,
      required: false,
      default: color.lightGray2
    }
  },
  computed: {
    searchLabel() {
      const label = this.$translateOrDefault(
        'general.text_shared.search_placeholder'
      )

      return `${label}`
    }
  },
  methods: {
    focus() {
      this.$refs.field.focus()
    }
  }
}
