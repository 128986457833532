
export default {
  name: 'SidebarFooter',
  data() {
    return {
      footerTitle: 'Est. 2023, Copr. No Lectin Food™'
    }
  },
  methods: {
    onItemClick() {
      this.$emit('close')
    }
  }
}
