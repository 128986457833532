import { FACEBOOK_PIXEL_EVENT } from '~/constants/facebookPixel'
import FirehoseClient from '~/api/FirehoseClient'

const amazonRegex = /^(?:https?:\/\/)?(?:www\.)?amazon\.com\/.+/

export default {
  methods: {
    $_linkTracking_trackLinkClick(location) {
      FirehoseClient.trackLinkClick(this.$route)

      if (process.env.ENVIRONMENT === 'local' || !location) return

      this.$helper.facebookTrackCustom(
        FACEBOOK_PIXEL_EVENT.AFFILIATE_LINK_CLICK,
        {
          location
        }
      )
    },
    $_linkTracking_isAmazonLink(href) {
      if (!href) return false

      return amazonRegex.test(href)
    }
  }
}
