// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#__nuxt .lang-picker__picker{flex-shrink:0;height:62px;width:100%}#__nuxt .lang-picker__picker .v-input__append-inner,#__nuxt .lang-picker__picker .v-input__prepend-inner{margin-top:18px}#__nuxt .lang-picker__picker .v-text-field--rounded>.v-input__control>.v-input__slot{padding:0 7px}#__nuxt .lang-picker__picker .lang-picker__picker-flag{margin-left:4px;margin-right:14px}@media(max-width:1439px){#__nuxt .lang-picker__picker .lang-picker__picker-flag{margin-right:0}}@media(max-width:599px){#__nuxt .lang-picker__picker .lang-picker__picker-flag{margin-right:10px}}@media(max-width:1439px){#__nuxt .lang-picker__picker .v-input__slot{background:#fff}}@media(max-width:599px){#__nuxt .lang-picker__picker .v-input__slot{background:#f8f8f8}}#__nuxt .lang-picker__picker .v-input{border-radius:12px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"redWCAG": "#c90b0b",
	"orangeWCAG": "#b85700",
	"greenWCAG": "#088722",
	"red": "#dc583a",
	"olive": "#9d9c2e",
	"yellowMain": "#fecb3d",
	"darkYellow": "#f3b02c",
	"lightGray1": "#f8f8f8",
	"lightGray2": "#eee",
	"darkGray3": "#777",
	"white": "#fff"
};
module.exports = ___CSS_LOADER_EXPORT___;
