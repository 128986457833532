import Vue from 'vue'
import {
  facebookTrackCustom,
  getRouteNameWithoutLocalePrefix
} from '~/plugins/helper'
import { FACEBOOK_PIXEL_EVENT } from '~/constants/facebookPixel'
import { ROUTE_NAME } from '~/constants/routeName'
import FirehoseClient from '~/api/FirehoseClient'

function getPagePathWithoutHash() {
  const { href, origin } = window.location
  const urlWithoutHash = href.split('#')[0]
  return urlWithoutHash.replace(origin, '')
}

const ROUTE_NAMES_TO_TRACK_PAGE_VIEW = [
  ROUTE_NAME.ARTICLE,
  ROUTE_NAME.NEWSLETTER,
  ROUTE_NAME.ABOUT,
  ROUTE_NAME.INGREDIENTS,
  ROUTE_NAME.CATEGORY,
  ROUTE_NAME.RECIPES,
  ROUTE_NAME.RECIPE
]

const GET_PAGE_PAYLOAD_BY_ROUTE_NAME = {
  [ROUTE_NAME.ARTICLE]: () => ({
    pageType: 'Article'
  }),
  [ROUTE_NAME.NEWSLETTER]: () => ({
    pageType: 'Newsletter'
  }),
  [ROUTE_NAME.ABOUT]: () => ({
    pageType: 'About'
  }),
  [ROUTE_NAME.INGREDIENTS]: () => ({
    pageType: 'Ingredients'
  }),
  [ROUTE_NAME.CATEGORY]: () => ({
    pageType: 'Category'
  }),
  [ROUTE_NAME.RECIPES]: () => ({
    pageType: 'Recipes'
  }),
  [ROUTE_NAME.RECIPE]: () => ({
    pageType: 'Recipe'
  })
}

export default function({ from, to, $sentry }) {
  const routeName = getRouteNameWithoutLocalePrefix(to && to.name)

  if (process.client) {
    FirehoseClient.trackPageVisitExit(from)
    FirehoseClient.trackPageVisitEntry(to)
  }

  if (
    !process.client ||
    process.env.ENVIRONMENT === 'local' ||
    !routeName ||
    !ROUTE_NAMES_TO_TRACK_PAGE_VIEW.includes(routeName)
  ) {
    return
  }

  Vue.nextTick().then(() => {
    const pathWithoutHash = getPagePathWithoutHash()

    try {
      facebookTrackCustom(FACEBOOK_PIXEL_EVENT.PAGE_VISIT, {
        path: decodeURIComponent(pathWithoutHash),
        ...GET_PAGE_PAYLOAD_BY_ROUTE_NAME[routeName](to)
      })
    } catch (err) {
      $sentry.captureException(err)
    }
  })
}
