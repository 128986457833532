export const SET_APP_VERSION = 'SET_APP_VERSION'
export const SET_SCROLL_TOP = 'SET_SCROLL_TOP'

// Global Search
export const UPDATE_SEARCH_PHRASE = 'UPDATE_SEARCH_PHRASE'

// Notifications
export const NOTIFICATION_SHOW = 'NOTIFICATION_SHOW'
export const NOTIFICATION_HIDE = 'NOTIFICATION_HIDE'
export const NOTIFICATION_RESET = 'NOTIFICATION_RESET'
export const NOTIFICATION_HISTORY_APPEND = 'NOTIFICATION_HISTORY_APPEND'

// Recipes
export const SET_RECIPE_INGREDIENTS_SELECTION_STATE =
  'SET_RECIPE_INGREDIENTS_SELECTION_STATE'
export const SET_RECIPE_STEPS_SELECTION_STATE =
  'SET_RECIPE_STEPS_SELECTION_STATE'
export const SET_TOTAL_BASE_WEIGHT_IN_GRAMS = 'SET_TOTAL_BASE_WEIGHT_IN_GRAMS'
