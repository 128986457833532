import * as types from '~/constants/mutationTypes'

function getScrollY() {
  return window.scrollY || window.pageYOffset
}

export default function({ app }) {
  if (!process.client) return

  const { store } = app.context

  store.commit(types.SET_SCROLL_TOP, getScrollY())

  document.addEventListener('scroll', () => {
    store.commit(types.SET_SCROLL_TOP, getScrollY())
  })
}
