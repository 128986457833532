export const HTML_TAG = {
  ANCHOR: 'a',
  IMAGE: 'img',
  IFRAME: 'iframe',
  LINK: 'link',
  DIV: 'div'
}

export const CUSTOM_TAG = {
  A_LINK: 'a-link'
}
