
import debounce from 'debounce'
import { mapGetters, mapMutations } from 'vuex'
import { SORT_DIRECTION } from '~/constants/sortDirection'
import * as mixins from '~/mixins'
import { UPDATE_SEARCH_PHRASE } from '~/constants/mutationTypes'
import AInfiniteScroll from '~/components/common/AInfiniteScroll/index.vue'
import ASpinner from '~/components/common/ASpinner/index.vue'
import ATablePromotionRow from '~/components/common/ATable/ATablePromotionRow/index.vue'
import ATableFilters from '~/components/common/ATable/ATableFilters/index.vue'
import { LOCALE } from '~/constants/locale'

const DEFAULT_CHUNK_SIZE = 50
const DEFAULT_PROMOTION_POSITION_PER_ROW_COUNT = 20

export default {
  name: 'ATable',
  components: {
    ATableFilters,
    ATablePromotionRow,
    ASpinner,
    AInfiniteScroll
  },
  mixins: [mixins.urlParams],
  props: {
    items: {
      type: Array,
      required: false,
      default: () => []
    },
    itemKey: {
      type: String,
      required: true
    },
    /**
     * Structure:
     *  {
     *    sortBy: 'prop1.prop2',
     *
     *    booleanFilters: [
     *      {
     *        field: 'is_lectin_free',
     *        titleI18nPath: 'general.filters.is_lectin_free',
     *        condition: value => value
     *      }
     *    ],
     *
     *    // When there is not enough space to fit columns, they are collapsed
     *    // considering the "columns[n].priority" rules
     *    collapsibleColumns: false,
     *
     *    // If provided, new table rows will be injected and rotated
     *    promotion: [
     *      {
     *        icon: '',
     *        title: 'Test',
     *        description: 'Test'
     *      }
     *    ],
     *
     *    promotionPositionPerRowCount: 20,
     *
     *    columns: [
     *      {
     *        filterable: true,
     *        name: 'Header',
     *        value: 'prop1.prop2',
     *
     *        // will be considered when filtering
     *        alternativeValue: 'prop1.prop2',
     *
     *        className: 'class',
     *        width: 100,
     *
     *        // Priority is considered when collapsing columns. The lowest
     *        // priority is 0, the highest - 1. When there is not enough space
     *        // for the table to fit all the data horizontally, the columns
     *        // will collapse starting from the lowest priority to the highest
     *        // one.
     *        //
     *        // * Note: only used when collapsibleColumns is set to true
     *        // If priority is not set or is set to 1 - the column will not
     *        // collapse
     *        priority: 1
     *      }
     *    ]
     *  }
     */
    settings: {
      type: Object,
      required: true
    },
    hideHeader: {
      type: Boolean,
      required: false,
      default: false
    },
    saveStateInQuery: {
      type: Boolean,
      required: false,
      default: false
    },
    headerSticky: {
      type: Boolean,
      required: false,
      default: false
    },
    filterWithGlobalSearch: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      onSearchUpdateDebounced: debounce(this.onSearchUpdate, 200),
      recalculateColumnsDebounced: debounce(this.recalculateColumns, 200),
      isColumnCollapsedByColumnName: {},
      chunkLoadMultiplier: 1,
      promotionRandomIndexes: [],
      selectedFilters: [],
      visibleExpandableRowKeys: [],
      search: ''
    }
  },
  computed: {
    ...mapGetters({
      searchPhrase: 'globalSearch/searchPhrase'
    }),
    colCount() {
      return this.settings.columns.length
    },
    resizeObserver() {
      // fallback for old browsers
      if (!('ResizeObserver' in window)) {
        return {
          observe: () => {},
          unobserve: () => {}
        }
      }

      return new ResizeObserver(() => this.recalculateColumnsDebounced())
    },
    tableWrapperDynamicClass() {
      return {
        headless: this.hideHeader,
        'header-sticky': this.headerSticky
      }
    },
    resultingSearch() {
      if (this.filterWithGlobalSearch) {
        return this.searchPhrase
      }

      return this.search
    },
    itemsFormatted() {
      const filteredItems = this.getFilteredItems()

      const { sortBy } = this.settings
      if (!sortBy) return filteredItems

      return filteredItems
        .sort(this.$helper.sortBy(sortBy, SORT_DIRECTION.ASC))
        .slice(0, DEFAULT_CHUNK_SIZE * this.chunkLoadMultiplier)
    },
    isAnyColumnCollapsed() {
      return Object.values(this.isColumnCollapsedByColumnName).some(v => v)
    },
    chunkLoadMultiplierMax() {
      const filteredItems = this.getFilteredItems()
      return Math.ceil(filteredItems.length / DEFAULT_CHUNK_SIZE)
    },
    isAllDataLoaded() {
      return this.chunkLoadMultiplier >= this.chunkLoadMultiplierMax
    },
    promotionPositionPerRowCount() {
      return (
        this.settings.promotionPositionPerRowCount ||
        DEFAULT_PROMOTION_POSITION_PER_ROW_COUNT
      )
    },
    isPromotionPositionPerRowCountValid() {
      return (
        Number.isInteger(this.promotionPositionPerRowCount) &&
        this.promotionPositionPerRowCount > 1
      )
    },
    withExpandableRows() {
      return !!this.$scopedSlots.expandableRowContent
    }
  },
  watch: {
    resultingSearch() {
      this.onSearchUpdateDebounced()
    }
  },
  created() {
    this.setCollapsedColumnStateFromProps()
  },
  mounted() {
    if (this.saveStateInQuery) {
      this.setDataFromUrlQueryOnInit()
    }

    if (this.settings.promotion) {
      this.generatePromotionPositions()
    }

    if (!this.$refs.tableCol) return

    if (this.settings.collapsibleColumns) {
      this.resizeObserver.observe(this.$el)
      this.recalculateColumns()
    }
  },
  beforeDestroy() {
    if (this.settings.collapsibleColumns) {
      this.resizeObserver.unobserve(this.$el)
    }
  },
  methods: {
    ...mapMutations({
      updateSearchPhrase: `globalSearch/${UPDATE_SEARCH_PHRASE}`
    }),
    onRowClick(item) {
      this.$emit('click:row', item)

      if (this.withExpandableRows) {
        this.visibleExpandableRowKeys = [
          ...this.visibleExpandableRowKeys,
          item[this.itemKey]
        ]
      }
    },
    onExpandableRowClick(item) {
      if (this.withExpandableRows) {
        this.visibleExpandableRowKeys = this.visibleExpandableRowKeys.filter(
          key => key !== item[this.itemKey]
        )
      }
    },
    isExpandableRowVisible(item) {
      if (!this.withExpandableRows) return false

      return this.visibleExpandableRowKeys.includes(item[this.itemKey])
    },
    generatePromotionPositions() {
      if (!this.isPromotionPositionPerRowCountValid) {
        console.error(
          `promotionPositionPerRowCount has invalid value: ${this.promotionPositionPerRowCount}`
        )

        return
      }

      const promotionsCount = Math.floor(
        this.items.length / (this.promotionPositionPerRowCount - 1)
      )

      const promotionsLength = this.settings.promotion.length

      const result = []
      let pool = Array.from({ length: promotionsLength }, (_, i) => i)

      for (let i = 0; i < promotionsCount; i++) {
        if (pool.length === 0) {
          // Reset the pool if it's empty
          pool = Array.from({ length: promotionsLength }, (_, i) => i)
        }

        const randomIndex = this.$helper.getRandom(0, pool.length - 1)
        result.push(pool[randomIndex])

        // Remove the chosen number from the pool
        pool.splice(randomIndex, 1)
      }

      this.promotionRandomIndexes = result
    },
    isPromotionPosition(index) {
      const { promotion } = this.settings

      if (
        this.$i18n.locale !== LOCALE.EN ||
        !promotion ||
        !promotion.length ||
        !this.promotionRandomIndexes.length
      ) {
        return false
      }

      return (index + 1) % (this.promotionPositionPerRowCount - 1) === 0
    },
    getFilteredItems() {
      return this.items
        .filter(this.searchCondition)
        .filter(this.filterCondition)
    },
    resetChunkLoadMultiplier() {
      this.chunkLoadMultiplier = 1
    },
    onLoadMore() {
      if (this.isAllDataLoaded) {
        return
      }

      this.chunkLoadMultiplier++
    },
    getTableCellDynamicStyle(column) {
      const { width, cellPadding } = column

      const paddingStyle = cellPadding ? { padding: cellPadding } : {}
      const widthStyle =
        width && !this.isColumnCollapsedByColumnName[column.name]
          ? { width: `${width}px` }
          : {}

      return {
        ...widthStyle,
        ...paddingStyle
      }
    },
    sortColumnsByPriorityDesc(a, b) {
      return (b.priority || 1) - (a.priority || 1)
    },
    sortColumnsByPriorityAsc(a, b) {
      return (a.priority || 1) - (b.priority || 1)
    },
    recalculateColumns() {
      if (!this.$el || !this.$refs.table) return

      const wrapperWidth = this.$el.clientWidth
      const tableWidth = this.$refs.table.clientWidth

      // everything fits in this case
      const isNothingToCollapseOrExpand =
        wrapperWidth >= tableWidth && !this.isAnyColumnCollapsed

      if (isNothingToCollapseOrExpand) return

      if (wrapperWidth < tableWidth) {
        this.collapseColumns()
      } else {
        this.expandColumns()
      }
    },
    async expandColumns() {
      const sortedColumns = [...this.settings.columns].sort(
        this.sortColumnsByPriorityDesc
      )

      for (const column of sortedColumns) {
        const isCollapsed = this.isColumnCollapsedByColumnName[column.name]

        if (!isCollapsed) {
          continue
        }

        this.isColumnCollapsedByColumnName[column.name] = false

        await this.$nextTick()
        const updatedTableWidth = this.$refs.table.clientWidth

        if (updatedTableWidth > this.$el.clientWidth) {
          this.isColumnCollapsedByColumnName[column.name] = true
          return
        }
      }
    },
    async collapseColumns() {
      /**
       * Columns are reversed to ensure the columns with the same priority are
       * collapsed from right to left
       */
      const sortedColumns = [...this.settings.columns]
        .reverse()
        .sort(this.sortColumnsByPriorityAsc)

      for (const column of sortedColumns) {
        if (!column.priority || column.priority === 1) return

        this.isColumnCollapsedByColumnName[column.name] = true

        await this.$nextTick()
        const updatedTableWidth = this.$refs.table.clientWidth

        if (updatedTableWidth <= this.$el.clientWidth) {
          return
        }
      }
    },
    setCollapsedColumnStateFromProps() {
      if (this.hideHeader) return

      this.isColumnCollapsedByColumnName = this.settings.columns.reduce(
        (acc, colSettings) => {
          acc[colSettings.name] = false
          return acc
        },
        {}
      )
    },
    setDataFromUrlQueryOnInit() {
      // TODO consider reading from URL params for consistency
      const { search, filters } = this.$route.query

      if (this.filterWithGlobalSearch) {
        this.updateSearchPhrase(search)
      } else {
        this.search = search
      }

      this.selectedFilters = Array.from(filters || []).map(filter =>
        Number(filter)
      )
    },
    updateUrlParams() {
      this.$_urlParams_updateUrlWithParams({
        search: this.resultingSearch,
        filters: this.selectedFilters
      })
    },
    onSearchUpdate() {
      this.resetChunkLoadMultiplier()

      if (this.saveStateInQuery) {
        this.updateUrlParams()
      }
    },
    onFiltersUpdate(filters) {
      this.resetChunkLoadMultiplier()

      this.selectedFilters = filters

      if (this.saveStateInQuery) {
        this.updateUrlParams()
      }
    },
    convertToString(value) {
      return `${value}`
    },
    filterCondition(item) {
      const { booleanFilters } = this.settings

      if (!this.selectedFilters.length) return true

      return this.selectedFilters.every(filterIndex => {
        const targetFilter = booleanFilters[filterIndex]
        const filterValue = item[targetFilter.field]

        if (targetFilter.condition) {
          return targetFilter.condition(filterValue)
        }

        return !!filterValue
      })
    },
    searchCondition(item) {
      if (!this.resultingSearch) return true

      return this.settings.columns
        .filter(col => col.filterable !== false)
        .some(col => {
          const valueToMatch = this.convertToString(
            this.$helper.getObjValueByPath(item, col.value)
          )?.toLowerCase()

          const alternativeValueToMatch = col.alternativeValue
            ? this.convertToString(
                this.$helper.getObjValueByPath(item, col.alternativeValue)
              )?.toLowerCase()
            : ''

          const searchFormatted = this.resultingSearch?.toLowerCase()

          return (
            valueToMatch?.includes(searchFormatted) ||
            alternativeValueToMatch?.includes(searchFormatted)
          )
        })
    }
  }
}
