import color from '~/assets/styles/color.scss'

export const CHIP_COLOR_SETTINGS = {
  SUCCESS: {
    color: '#EBEBD5',
    textColor: color.olive
  },
  ERROR: {
    color: '#F8DED8',
    textColor: color.red
  }
}

export const CHIP_COLOR_WITH_ICON_SETTINGS = {
  SUCCESS: {
    i18nPath: 'general.text_shared.yes',
    color: color.olive,
    textColor: '#EBEBD5',
    icon: '$markFilled'
  },
  WARNING: {
    i18nPath: 'general.text_shared.fixable',
    color: color.darkYellow,
    textColor: '#F7EDD9',
    icon: '$stopFilled'
  },
  ERROR: {
    i18nPath: 'general.text_shared.no',
    color: color.red,
    textColor: '#F8DED8',
    icon: '$crossFilled'
  }
}

export const DEFAULT_COLOR_SETTINGS_BY_VALUE = value => {
  if (value) {
    return {
      i18nPath: 'general.text_shared.yes',
      ...CHIP_COLOR_SETTINGS.SUCCESS
    }
  }

  return {
    i18nPath: 'general.text_shared.no',
    ...CHIP_COLOR_SETTINGS.ERROR
  }
}

export const DEFAULT_COLOR_WITH_ICON_SETTINGS_BY_VALUE = value => {
  if (value) {
    return {
      i18nPath: 'general.text_shared.yes',
      ...CHIP_COLOR_WITH_ICON_SETTINGS.SUCCESS
    }
  }

  return {
    i18nPath: 'general.text_shared.no',
    ...CHIP_COLOR_WITH_ICON_SETTINGS.ERROR
  }
}
