
import { DEFAULT_COLOR_SETTINGS_BY_VALUE } from '~/constants/statusChip'

const DEFAULT_MIN_WIDTH = 64
const DEFAULT_MIN_WIDTH_WITH_ICON = 90

export default {
  name: 'AStatusChip',
  props: {
    value: {
      type: [String, Boolean, Number, null],
      required: false,
      default: ''
    },
    hideIcon: {
      type: Boolean,
      required: false,
      default: false
    },

    /**
     * Depending on the boolean value, returns settings object
     * @param value {boolean}
     *
     * @returns
     * {
     *   // if returned, takes precedence over "value"
     *   i18nPath: 'path.to.translation',
     *   value: 'text to display',
     *   color: 'red',
     *   icon: '$magnify'
     * }
     */
    settingsFn: {
      type: Function,
      required: false,
      default: DEFAULT_COLOR_SETTINGS_BY_VALUE
    },
    minWidth: {
      type: [Number, null],
      required: false,
      default: undefined
    }
  },
  computed: {
    resultingMinWidth() {
      if (this.minWidth) return this.minWidth

      return this.isIconVisible
        ? DEFAULT_MIN_WIDTH_WITH_ICON
        : DEFAULT_MIN_WIDTH
    },
    isIconVisible() {
      return !this.hideIcon && this.currentSettings.icon
    },
    dynamicClass() {
      return {
        'with-icon': this.isIconVisible
      }
    },
    dynamicStyle() {
      return {
        minWidth: `${this.resultingMinWidth}px`
      }
    },
    currentSettings() {
      return this.settingsFn(this.value)
    },
    displayValue() {
      const { value, i18nPath } = this.currentSettings

      return i18nPath ? this.$translateOrDefault(i18nPath) : value
    },
    attrs() {
      return {
        ...this.$attrs,
        ...this.$helper.omitKeys(
          ['i18nPath', 'value', 'icon'],
          this.currentSettings
        )
      }
    }
  }
}
