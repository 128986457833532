
export default {
  name: 'ALink',
  props: {
    to: {
      type: [Object, String],
      required: false,
      default: null
    },
    openInNewTab: {
      type: Boolean,
      required: false,
      default: false
    },
    colorBlack: {
      type: Boolean,
      required: false,
      default: false
    },
    noUnderline: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    dynamicLinkClass() {
      return {
        'link__color-black': this.colorBlack,
        'link__no-underline': this.noUnderline
      }
    },
    isNuxtLink() {
      return this.to && !this.isExternal && !this.isHash
    },
    isHash() {
      const regex = /^#/
      return regex.test(this.to)
    },
    isExternal() {
      const regex = /^(http|https):\/\//
      return regex.test(this.to)
    },
    linkAttrs() {
      return {
        ...this.$attrs,
        ...(this.openInNewTab ? { target: '_blank', rel: 'noopener' } : {})
      }
    }
  }
}
