
import AppToolbarBottomMobileSearch from '~/components/general/AppToolbarBottomMobile/AppToolbarBottomMobileSearch/index.vue'
import { ROUTE_NAME } from '~/constants/routeName'
import {
  ROUTE_NAMES_TO_SHOW_GLOBAL_SEARCH_ON_MOBILE,
  SEARCH_ID
} from '~/constants/search'
import {
  RECIPES_ICON_SRC,
  INGREDIENTS_ICON_SRC,
  SITE_LOGO_BLACK_SRC
} from '~/constants/icon'
import { DEFAULT_SCROLL_DURATION } from '~/constants/scroll'

export default {
  name: 'AppToolbarBottomMobile',
  components: { AppToolbarBottomMobileSearch },
  props: {
    leftSidebarOpen: {
      type: Boolean,
      required: true
    }
  },
  consts: {
    ICON_SIZE: 20,
    RECIPES_ICON_SRC,
    INGREDIENTS_ICON_SRC,
    SITE_LOGO_BLACK_SRC
  },
  computed: {
    isGlobalSearchVisible() {
      return this.$helper.isRouteNameInList(
        this.$route.name,
        ROUTE_NAMES_TO_SHOW_GLOBAL_SEARCH_ON_MOBILE
      )
    },
    appToolbarDynamicClass() {
      return {
        'with-search': this.isGlobalSearchVisible
      }
    },
    recipesButtonDynamicClass() {
      return {
        'app-toolbar-bottom__recipes-link--active':
          !!this.leftSidebarOpen ||
          this.$helper.isRouteNameInList(this.$route.name, [
            ROUTE_NAME.CATEGORY
          ])
      }
    }
  },
  methods: {
    onToggleLeftSidebar() {
      this.$emit('toggle-left-sidebar')
    },
    onToggleRightSidebar() {
      this.$emit('toggle-right-sidebar')
    },
    scrollToSearch() {
      if (!this.isGlobalSearchVisible) return

      const searchId = SEARCH_ID.GLOBAL_SEARCH

      this.$helper.scrollTo({ ctx: this, selector: `#${searchId}` })

      setTimeout(() => {
        this.focusOnSearchbar(searchId)
      }, DEFAULT_SCROLL_DURATION)
    },
    focusOnSearchbar(searchId) {
      const element = document.getElementById(searchId)
      if (!element || typeof element.focus !== 'function') return

      element.focus()
    }
  }
}
