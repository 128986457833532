export function removeEndingSlash(str = '') {
  if (typeof str !== 'string') return str

  if (!str) return ''

  if (str.endsWith('/')) {
    return str.slice(0, -1)
  }

  return str
}
