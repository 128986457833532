import Vue from 'vue'
import ACard from '~/components/common/ACard'
import ABasicCard from '~/components/common/ABasicCard'
import PageTitle from '~/components/general/PageTitle'
import PageDescription from '~/components/general/PageDescription'
import PageContentContainer from '~/components/general/PageContentContainer'
import ATable from '~/components/common/ATable'
import AInput from '~/components/common/controls/AInput'
import ASearch from '~/components/common/controls/ASearch'
import AStatusChip from '~/components/common/AStatusChip'
import AStatusText from '~/components/common/AStatusText'
import AConditionalText from '~/components/common/AConditionalText/index.vue'
import AImage from '~/components/common/AImage'
import ALink from '~/components/common/ALink'

Vue.component(ACard.name, ACard)
Vue.component(ABasicCard.name, ABasicCard)
Vue.component(PageTitle.name, PageTitle)
Vue.component(PageDescription.name, PageDescription)
Vue.component(PageContentContainer.name, PageContentContainer)
Vue.component(ATable.name, ATable)
Vue.component(AInput.name, AInput)
Vue.component(ASearch.name, ASearch)
Vue.component(AStatusChip.name, AStatusChip)
Vue.component(AStatusText.name, AStatusText)
Vue.component(AConditionalText.name, AConditionalText)
Vue.component(AImage.name, AImage)
Vue.component(ALink.name, ALink)
