
export default {
  name: 'ASpinner',
  props: {
    absolute: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  computed: {
    spinnerDynamicClass() {
      return {
        absolute: this.absolute
      }
    }
  }
}
