
export default {
  name: 'AImageWithDummy',
  props: {
    src: {
      type: [String, null],
      required: false,
      default: null
    },
    imgAttrs: {
      type: Object,
      required: true
    },
    withDummy: {
      type: Boolean,
      required: false,
      default: true
    },
    fit: {
      type: String,
      required: false,
      default: 'contain'
    }
  },
  computed: {
    imgDynamicClass() {
      return [`a-image__image-fit--${this.fit}`]
    }
  }
}
