
export default {
  name: 'AppSidebar',
  props: {
    isSidebarOpen: {
      type: Boolean,
      required: true
    },
    right: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    sidebarDynamicClass() {
      return {
        sidebar__wrapper__collapsed: !this.isSidebarOpen,
        sidebar__wrapper__right: this.right
      }
    }
  },
  methods: {
    onSidebarToggle(isOpen) {
      if (isOpen !== this.isSidebarOpen) {
        this.$emit('update:isSidebarOpen', isOpen)
      }
    },
    closeSidebar() {
      this.$emit('update:isSidebarOpen', false)
    }
  }
}
