import color from '~/assets/styles/color.scss'

export default function getDefaultTextSettingsByValue(value) {
  if (value) {
    return {
      i18nPath: 'general.text_shared.yes',
      color: 'green'
    }
  }

  return {
    i18nPath: 'general.text_shared.no',
    color: color.redWCAG
  }
}
