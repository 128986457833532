
import { LOCALE } from '~/constants/locale'

export default {
  name: 'AAnimatedHint',
  props: {
    isHintActive: {
      type: Boolean,
      required: true
    },
    hintI18nPath: {
      type: String,
      required: false,
      default: ''
    },
    useEnglishTranslation: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    isHintVisible() {
      return this.hint && this.isHintActive
    },
    hint() {
      if (!this.hintI18nPath) return ''

      if (this.useEnglishTranslation) {
        return this.$t(this.hintI18nPath, LOCALE.EN)
      }

      return this.$translateOrDefault(this.hintI18nPath)
    }
  },
  mounted() {
    this.setHintAsActiveIfTouchDeviceDetected()
  },
  methods: {
    setHintAsActiveIfTouchDeviceDetected() {
      // if touch device, the hint is displayed by default
      if (this.$helper.isTouchDevice()) {
        this.$emit('update:isHintActive', true)
      }
    }
  }
}
