import * as types from '~/constants/mutationTypes'
import { facebookTrackCustom } from '~/plugins/helper'
import { FACEBOOK_PIXEL_EVENT } from '~/constants/facebookPixel'
import FirehoseClient from '~/api/FirehoseClient'

export const state = () => ({
  isEveryIngredientSelected: false,
  isEveryStepSelected: false,
  totalBaseWeightInGrams: 0
})

export const getters = {
  isEveryIngredientSelected: state => state.isEveryIngredientSelected,
  isEveryStepSelected: state => state.isEveryStepSelected,
  isEverythingSelected: state =>
    state.isEveryIngredientSelected && state.isEveryStepSelected,
  totalBaseWeightInGrams: state => state.totalBaseWeightInGrams
}

export const mutations = {
  [types.SET_RECIPE_INGREDIENTS_SELECTION_STATE](state, value) {
    state.isEveryIngredientSelected = value
  },
  [types.SET_RECIPE_STEPS_SELECTION_STATE](state, value) {
    state.isEveryStepSelected = value
  },
  [types.SET_TOTAL_BASE_WEIGHT_IN_GRAMS](state, value) {
    state.totalBaseWeightInGrams = value
  }
}

export const actions = {
  async setRecipeIngredientsSelectionState(
    { commit, dispatch, getters },
    value
  ) {
    commit(types.SET_RECIPE_INGREDIENTS_SELECTION_STATE, value)

    if (getters.isEverythingSelected) {
      await dispatch('trackRecipeDoneToFacebook')
    }
  },
  async setRecipeStepsSelectionState({ commit, dispatch, getters }, value) {
    commit(types.SET_RECIPE_STEPS_SELECTION_STATE, value)

    if (getters.isEverythingSelected) {
      await dispatch('trackRecipeDoneToFacebook')
    }
  },
  async trackRecipeDoneToFacebook() {
    facebookTrackCustom(FACEBOOK_PIXEL_EVENT.RECIPE_FINISHED)
  },
  async trackRecipeIngredientsSelectionStart(_, route) {
    FirehoseClient.trackRecipeIngredientsSelectionStart(route)
  },
  async trackRecipeIngredientsSelectionEnd(_, route) {
    FirehoseClient.trackRecipeIngredientsSelectionEnd(route)
  },
  async trackRecipeStepsSelectionStart(_, route) {
    FirehoseClient.trackRecipeStepsSelectionStart(route)
  },
  async trackRecipeStepsSelectionEnd(_, route) {
    FirehoseClient.trackRecipeStepsSelectionEnd(route)
  }
}
