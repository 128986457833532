export async function fetchAppVersion() {
  try {
    const response = await fetch('/version.json', { cache: 'no-store' })
    if (response.ok) {
      const { version } = await response.json()
      return version
    }
    throw new Error('Failed to fetch server version')
  } catch (error) {
    console.error('Error fetching server version:', error)
    return null
  }
}
