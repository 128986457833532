
import AGlobalSearch from '~/components/general/AGlobalSearch/index.vue'
import { ROUTE_NAME } from '~/constants/routeName'

const PAGES_WITH_CUSTOM_GLOBAL_SEARCH = [ROUTE_NAME.INGREDIENTS]

export default {
  name: 'AppToolbarBottomMobileSearch',
  components: { AGlobalSearch },
  data() {
    return {
      isGlobalSearchVisible: false
    }
  },
  computed: {
    isGlobalSearchVisibleCondition() {
      return (
        this.isGlobalSearchVisible && this.isGlobalSearchVisibleOnCurrentPage
      )
    },
    isGlobalSearchVisibleOnCurrentPage() {
      return this.$helper.isRouteNameInList(
        this.$route.name,
        PAGES_WITH_CUSTOM_GLOBAL_SEARCH
      )
    }
  },
  watch: {
    '$route.name': {
      handler() {
        this.isGlobalSearchVisible = false
      }
    }
  },
  methods: {
    onSearchClick() {
      if (this.isGlobalSearchVisibleOnCurrentPage) {
        this.toggleGlobalSearch()
        setTimeout(this.focus)
      } else {
        this.$emit('scroll-to-search')
      }
    },
    focus() {
      this.$refs.searchbar.focus()
    },
    toggleGlobalSearch() {
      this.isGlobalSearchVisible = !this.isGlobalSearchVisible
    }
  }
}
