// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".a-search__input.v-text-field--rounded{border-radius:12px}.a-search__input.a-input .v-input__slot{background-color:#eee;padding-left:12px;padding-right:12px}.a-search__input .v-label{color:#ccc;top:auto;transform:translateY(0)}.a-search__input .v-input__append-inner,.a-search__input .v-input__prepend-inner{margin-top:8px}.a-search__input svg *{fill:#777}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"redWCAG": "#c90b0b",
	"orangeWCAG": "#b85700",
	"greenWCAG": "#088722",
	"red": "#dc583a",
	"olive": "#9d9c2e",
	"yellowMain": "#fecb3d",
	"darkYellow": "#f3b02c",
	"lightGray1": "#f8f8f8",
	"lightGray2": "#eee",
	"darkGray3": "#777",
	"white": "#fff"
};
module.exports = ___CSS_LOADER_EXPORT___;
