// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".a-markdown-preview__wrapper{overflow-x:auto;word-break:break-word}.a-markdown-preview__wrapper code,.a-markdown-preview__wrapper h1,.a-markdown-preview__wrapper h2,.a-markdown-preview__wrapper h3,.a-markdown-preview__wrapper h4,.a-markdown-preview__wrapper h5,.a-markdown-preview__wrapper h6,.a-markdown-preview__wrapper ol,.a-markdown-preview__wrapper p,.a-markdown-preview__wrapper pre,.a-markdown-preview__wrapper table,.a-markdown-preview__wrapper ul{margin-bottom:16px}.a-markdown-preview__wrapper code:last-child,.a-markdown-preview__wrapper h1:last-child,.a-markdown-preview__wrapper h2:last-child,.a-markdown-preview__wrapper h3:last-child,.a-markdown-preview__wrapper h4:last-child,.a-markdown-preview__wrapper h5:last-child,.a-markdown-preview__wrapper h6:last-child,.a-markdown-preview__wrapper ol:last-child,.a-markdown-preview__wrapper p:last-child,.a-markdown-preview__wrapper pre:last-child,.a-markdown-preview__wrapper table:last-child,.a-markdown-preview__wrapper ul:last-child{margin-bottom:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"redWCAG": "#c90b0b",
	"orangeWCAG": "#b85700",
	"greenWCAG": "#088722",
	"red": "#dc583a",
	"olive": "#9d9c2e",
	"yellowMain": "#fecb3d",
	"darkYellow": "#f3b02c",
	"lightGray1": "#f8f8f8",
	"lightGray2": "#eee",
	"darkGray3": "#777",
	"white": "#fff"
};
module.exports = ___CSS_LOADER_EXPORT___;
