export const ROUTE_NAME = {
  INDEX: 'index',
  RECIPE: 'recipes-category-recipe',
  RECIPES: 'recipes',
  CATEGORY: 'recipes-category',
  ABOUT: 'about',
  PRIVACY: 'privacy-policy',
  TERMS_OF_SERVICE: 'terms-of-service',
  INGREDIENTS: 'ingredients',
  INGREDIENT: 'ingredients-ingredient',
  ARTICLES: 'articles',
  ARTICLE: 'articles-article',
  NEWSLETTER: 'newsletter',
  NEWSLETTER_UNSUBSCRIBE: 'newsletter-unsubscribe'
}
