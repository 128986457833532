
export default {
  name: 'ACard',
  props: {
    to: {
      type: [Object, String],
      required: false,
      default: null
    },
    linkColorBlack: {
      type: Boolean,
      required: false,
      default: false
    },
    linkNoUnderline: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
