import * as types from '~/constants/mutationTypes'

export const state = () => ({
  searchPhrase: ''
})

export const getters = {
  searchPhrase: state => state.searchPhrase
}

export const mutations = {
  [types.UPDATE_SEARCH_PHRASE](state, value) {
    state.searchPhrase = value || ''
  }
}
