
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'AppNotifications',

  computed: {
    ...mapGetters({
      notification: 'notifications/current'
    }),

    isShownNotification: {
      get() {
        return this.notification.isShown
      },
      set() {
        this.hide()
      }
    }
  },

  methods: {
    ...mapActions({
      hide: 'notifications/hide'
    })
  }
}
