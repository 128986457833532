import { isObject } from '~/plugins/helper'

export function cleanObject(obj) {
  // Function to determine if the value is empty (for arrays and objects)
  const isEmpty = value => {
    if (Array.isArray(value)) {
      return value.length === 0
    }

    if (isObject(value)) {
      return Object.keys(value).length === 0
    }

    return false
  }

  // Recursively clean the object or array
  function clean(value) {
    if (Array.isArray(value)) {
      // Filter out null or undefined, then clean each element
      const cleanedArray = value
        .filter(item => item !== null && item !== undefined)
        .map(clean)
        .filter(item => !isEmpty(item))
      return cleanedArray.length > 0 ? cleanedArray : null // Return null to signal removal if array is empty after cleaning
    }

    if (isObject(value)) {
      // Recursively apply cleaning to object properties
      const cleanedObject = Object.entries(value).reduce((acc, [key, val]) => {
        const cleanedValue = clean(val)
        if (cleanedValue !== null && cleanedValue !== undefined) {
          acc[key] = cleanedValue
        }
        return acc
      }, {})
      return !isEmpty(cleanedObject) ? cleanedObject : null // Return null to signal removal if object is empty after cleaning
    }
    // Return the value directly if it's neither an object nor an array
    return value
  }

  const result = clean(obj)
  // If the result is an object and not null, return it; otherwise, return an empty object to avoid returning null at the top level
  return isObject(result) ? result : {}
}
