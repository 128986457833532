
export default {
  name: 'ASelect',
  props: {
    value: {
      type: [String, null],
      required: false,
      default: null
    },
    items: {
      type: Array,
      required: true
    }
  }
}
