
import AMarkdownPreview from '~/components/common/AMarkdownPreview/index.vue'

export default {
  name: 'ATablePromotionContent',
  components: { AMarkdownPreview },
  props: {
    promotion: {
      type: Object,
      required: true
    },
    columnCount: {
      type: Number,
      required: true
    }
  }
}
