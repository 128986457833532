export default {
  data() {
    return {
      isMounted: false
    }
  },
  computed: {
    $_hydration_isMobile() {
      return this.isMounted && this.$vuetify.breakpoint.xsOnly
    }
  },
  mounted() {
    this.isMounted = true
  }
}
