
import SidebarListItem from '~/components/general/AppSidebar/SidebarListItem/index.vue'

export default {
  name: 'SidebarContentMobileLeft',
  components: { SidebarListItem },
  props: {
    categoryItems: {
      type: Array,
      required: true
    }
  }
}
