
import { CUSTOM_TAG, HTML_TAG } from '~/constants/htmlTag'

export default {
  name: 'ABasicCard',
  props: {
    to: {
      type: [Object, String],
      required: false,
      default: null
    },
    noHoverEffect: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    dynamicClass() {
      return {
        'no-hover': this.noHoverEffect
      }
    },
    wrapperTag() {
      return this.to ? CUSTOM_TAG.A_LINK : HTML_TAG.DIV
    },
    wrapperProps() {
      const defaultProps = this.$attrs

      const linkProps = {
        colorBlack: true,
        noUnderline: true,
        to: this.to,
        ...defaultProps
      }

      return this.to ? linkProps : defaultProps
    }
  }
}
