export const CATEGORY_SLUG = {
  BEVANDE: 'bevande',
  STUZZICHINI: 'stuzzichini',
  PRIMI: 'primi',
  SECONDI: 'secondi',
  CONTORNI: 'contorni',
  DOLCI: 'dolci',
  SUGHI: 'sughi',
  PESTI: 'pesti',
  GELATO: 'gelati'
}

export const CATEGORY_SLUG_VALUES = Object.values(CATEGORY_SLUG)

export const CATEGORY_TITLE_BY_CATEGORY_SLUG = {
  [CATEGORY_SLUG.BEVANDE]: 'Bevande',
  [CATEGORY_SLUG.STUZZICHINI]: 'Stuzzichini',
  [CATEGORY_SLUG.PRIMI]: 'Primi',
  [CATEGORY_SLUG.SECONDI]: 'Secondi',
  [CATEGORY_SLUG.CONTORNI]: 'Contorni',
  [CATEGORY_SLUG.DOLCI]: 'Dolci',
  [CATEGORY_SLUG.SUGHI]: 'Sughi',
  [CATEGORY_SLUG.PESTI]: 'Pesti',
  [CATEGORY_SLUG.GELATO]: 'Gelati'
}

export const CATEGORY_I18N_PATH_PARAM_BY_CATEGORY_SLUG = {
  [CATEGORY_SLUG.BEVANDE]: 'bevande',
  [CATEGORY_SLUG.STUZZICHINI]: 'stuzzichini',
  [CATEGORY_SLUG.PRIMI]: 'primi',
  [CATEGORY_SLUG.SECONDI]: 'secondi',
  [CATEGORY_SLUG.CONTORNI]: 'contorni',
  [CATEGORY_SLUG.DOLCI]: 'dolci',
  [CATEGORY_SLUG.SUGHI]: 'sughi',
  [CATEGORY_SLUG.PESTI]: 'pesti',
  [CATEGORY_SLUG.GELATO]: 'gelati'
}

export const CATEGORY_ICON_SRC_BY_CATEGORY_SLUG = {
  [CATEGORY_SLUG.BEVANDE]: '/images/Bevande.png',
  [CATEGORY_SLUG.STUZZICHINI]: '/images/Stuzzichini.png',
  [CATEGORY_SLUG.PRIMI]: '/images/Primi.png',
  [CATEGORY_SLUG.SECONDI]: '/images/Secondi.png',
  [CATEGORY_SLUG.CONTORNI]: '/images/Contorni.png',
  [CATEGORY_SLUG.DOLCI]: '/images/Dolci.png',
  [CATEGORY_SLUG.SUGHI]: '/images/Sughi.png',
  [CATEGORY_SLUG.PESTI]: '/images/Pesti.png',
  [CATEGORY_SLUG.GELATO]: '/images/Gelati.png'
}

export const CATEGORY_HINT_I18N_PATH_BY_CATEGORY_SLUG = {
  [CATEGORY_SLUG.BEVANDE]: 'general.sidebar.hints.bevande',
  [CATEGORY_SLUG.STUZZICHINI]: 'general.sidebar.hints.stuzzichini',
  [CATEGORY_SLUG.PRIMI]: 'general.sidebar.hints.primi',
  [CATEGORY_SLUG.SECONDI]: 'general.sidebar.hints.secondi',
  [CATEGORY_SLUG.CONTORNI]: 'general.sidebar.hints.contorni',
  [CATEGORY_SLUG.DOLCI]: 'general.sidebar.hints.dolci',
  [CATEGORY_SLUG.SUGHI]: 'general.sidebar.hints.sughi',
  [CATEGORY_SLUG.PESTI]: 'general.sidebar.hints.pesti',
  [CATEGORY_SLUG.GELATO]: 'general.sidebar.hints.gelati'
}

export const CATEGORY_ALT_TEXT_I18N_PATH_BY_CATEGORY_SLUG = {
  [CATEGORY_SLUG.BEVANDE]: 'general.sidebar.alt_text.bevande',
  [CATEGORY_SLUG.STUZZICHINI]: 'general.sidebar.alt_text.stuzzichini',
  [CATEGORY_SLUG.PRIMI]: 'general.sidebar.alt_text.primi',
  [CATEGORY_SLUG.SECONDI]: 'general.sidebar.alt_text.secondi',
  [CATEGORY_SLUG.CONTORNI]: 'general.sidebar.alt_text.contorni',
  [CATEGORY_SLUG.DOLCI]: 'general.sidebar.alt_text.dolci',
  [CATEGORY_SLUG.SUGHI]: 'general.sidebar.alt_text.sughi',
  [CATEGORY_SLUG.PESTI]: 'general.sidebar.alt_text.pesti',
  [CATEGORY_SLUG.GELATO]: 'general.sidebar.alt_text.gelati'
}

export function getCategoryNavItems(routes) {
  return Object.entries(CATEGORY_TITLE_BY_CATEGORY_SLUG).map(
    ([categorySlug, categoryTitle]) => ({
      icon: CATEGORY_ICON_SRC_BY_CATEGORY_SLUG[categorySlug],
      title: categoryTitle,
      slug: categorySlug,
      hintI18nPath: CATEGORY_HINT_I18N_PATH_BY_CATEGORY_SLUG[categorySlug],
      altTextI18nPath:
        CATEGORY_ALT_TEXT_I18N_PATH_BY_CATEGORY_SLUG[categorySlug],
      to: routes.category({ category: categorySlug }).route
    })
  )
}
