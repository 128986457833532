
import debounce from 'debounce'
import { mapGetters, mapMutations } from 'vuex'
import { UPDATE_SEARCH_PHRASE } from '~/constants/mutationTypes'
import * as mixins from '~/mixins'
import { FACEBOOK_PIXEL_EVENT } from '~/constants/facebookPixel'
import color from '~/assets/styles/color.scss'

const MINIMUM_SEARCH_PHRASE_LENGTH_TO_TRACK = 3

export default {
  name: 'AGlobalSearch',
  mixins: [mixins.hydration],
  inheritAttrs: false,
  props: {
    inputId: {
      type: String,
      required: false,
      default: ''
    },
    backgroundColor: {
      type: String,
      required: false,
      default: color.lightGray2
    }
  },
  data() {
    return {
      trackToFbDebounced: debounce(this.trackToFacebook, 500)
    }
  },
  computed: {
    ...mapGetters({
      searchPhrase: 'globalSearch/searchPhrase'
    }),
    searchLabel() {
      const label = this.$translateOrDefault(
        'general.text_shared.search_placeholder'
      )

      return `${label}`
    },
    search: {
      get() {
        return this.searchPhrase
      },
      set(value) {
        this.updateSearchPhrase(value)
        this.trackToFbDebounced()
      }
    }
  },
  watch: {
    '$route.name': {
      handler() {
        this.clearInput()
      }
    }
  },
  beforeDestroy() {
    this.clearInput()
  },
  methods: {
    ...mapMutations({
      updateSearchPhrase: `globalSearch/${UPDATE_SEARCH_PHRASE}`
    }),
    trackToFacebook() {
      if (
        !this.searchPhrase ||
        this.searchPhrase.length < MINIMUM_SEARCH_PHRASE_LENGTH_TO_TRACK
      ) {
        return
      }

      this.$helper.facebookTrack(FACEBOOK_PIXEL_EVENT.SEARCH, {
        searchString: this.searchPhrase
      })
    },
    clearInput() {
      this.updateSearchPhrase('')
    },
    focus() {
      this.$refs.field.focus()
    }
  }
}
