
import { mapGetters } from 'vuex'
import ATablePromotionContent from '~/components/common/ATable/ATablePromotionRow/ATablePromotionContent/index.vue'

export default {
  name: 'ATablePromotionRow',
  components: { ATablePromotionContent },
  props: {
    rowIndex: {
      type: Number,
      required: true
    },
    promotionPositionPerRowCount: {
      type: Number,
      required: true
    },
    settings: {
      type: Array,
      required: true
    },
    promotionRandomIndexes: {
      type: Array,
      required: true
    },
    columnCount: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      isRowFrozen: false
    }
  },
  computed: {
    ...mapGetters({
      scrollTop: 'scrollTop'
    }),
    currentPromotion() {
      const realIndex =
        (this.rowIndex + 1) / (this.promotionPositionPerRowCount - 1) - 1

      const givenRandomIndex = this.promotionRandomIndexes[realIndex]
      return this.settings[givenRandomIndex]
    },
    rowDynamicClass() {
      return {
        frozen: this.isRowFrozen
      }
    }
  },
  watch: {
    scrollTop: {
      immediate: true,
      handler() {
        if (!process.client || !this.$vuetify.breakpoint.xsOnly) return

        this.updatePromotionPosition()
      }
    }
  },
  methods: {
    updatePromotionPosition() {
      if (!this.$el) return

      const { top } = this.$el.getBoundingClientRect()

      if (top <= 0) {
        this.freezePromotionRow()
      } else {
        this.unfreezePromotionRow()
      }
    },
    freezePromotionRow() {
      this.isRowFrozen = true
    },
    unfreezePromotionRow() {
      this.isRowFrozen = false
    }
  }
}
