import { ROUTE_NAME } from '~/constants/routeName'
import { CATEGORY_SLUG } from '~/constants/category'

export const DEFAULT_FAVICONS = () => [
  {
    rel: 'icon',
    type: 'image/png',
    sizes: '32x32',
    href: '/favicons/favicon-32x32.png'
  },
  {
    rel: 'icon',
    type: 'image/png',
    sizes: '16x16',
    href: '/favicons/favicon-16x16.png'
  }
]

const FAVICON_BY_CATEGORY = {
  [CATEGORY_SLUG.BEVANDE]: [
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '32x32',
      href: '/favicons/Bevande-32x32.png'
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '16x16',
      href: '/favicons/Bevande-16x16.png'
    }
  ],
  [CATEGORY_SLUG.CONTORNI]: [
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '32x32',
      href: '/favicons/Contorni-32x32.png'
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '16x16',
      href: '/favicons/Contorni-16x16.png'
    }
  ],
  [CATEGORY_SLUG.DOLCI]: [
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '32x32',
      href: '/favicons/Dolci-32x32.png'
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '16x16',
      href: '/favicons/Dolci-16x16.png'
    }
  ],
  [CATEGORY_SLUG.PRIMI]: [
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '32x32',
      href: '/favicons/Primi-32x32.png'
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '16x16',
      href: '/favicons/Primi-16x16.png'
    }
  ],
  [CATEGORY_SLUG.PESTI]: [
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '32x32',
      href: '/favicons/Pesti-32x32.png'
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '16x16',
      href: '/favicons/Pesti-16x16.png'
    }
  ],
  [CATEGORY_SLUG.GELATO]: [
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '32x32',
      href: '/favicons/Gelati-32x32.png'
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '16x16',
      href: '/favicons/Gelati-16x16.png'
    }
  ],
  [CATEGORY_SLUG.SECONDI]: [
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '32x32',
      href: '/favicons/Secondi-32x32.png'
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '16x16',
      href: '/favicons/Secondi-16x16.png'
    }
  ],
  [CATEGORY_SLUG.STUZZICHINI]: [
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '32x32',
      href: '/favicons/Stuzzichini-32x32.png'
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '16x16',
      href: '/favicons/Stuzzichini-16x16.png'
    }
  ],
  [CATEGORY_SLUG.SUGHI]: [
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '32x32',
      href: '/favicons/Sughi-32x32.png'
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '16x16',
      href: '/favicons/Sughi-16x16.png'
    }
  ]
}

export const FAVICONS_BY_ROUTE_NAME = {
  [ROUTE_NAME.INGREDIENTS]: () => [
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '32x32',
      href: '/favicons/Ingredients-32x32.png'
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '16x16',
      href: '/favicons/Ingredients-16x16.png'
    }
  ],
  [ROUTE_NAME.INGREDIENT]: () => [
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '32x32',
      href: '/favicons/Ingredients-32x32.png'
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '16x16',
      href: '/favicons/Ingredients-16x16.png'
    }
  ],
  [ROUTE_NAME.RECIPES]: () => [
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '32x32',
      href: '/favicons/Recipes-32x32.png'
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '16x16',
      href: '/favicons/Recipes-16x16.png'
    }
  ],
  [ROUTE_NAME.RECIPE]: routeParams =>
    FAVICON_BY_CATEGORY[routeParams.category?.toLowerCase()],
  [ROUTE_NAME.ABOUT]: () => [
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '32x32',
      href: '/favicons/About-32x32.png'
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '16x16',
      href: '/favicons/About-16x16.png'
    }
  ],
  [ROUTE_NAME.ARTICLES]: () => [
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '32x32',
      href: '/favicons/Articles-32x32.png'
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '16x16',
      href: '/favicons/Articles-16x16.png'
    }
  ],
  [ROUTE_NAME.ARTICLE]: () => [
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '32x32',
      href: '/favicons/Articles-32x32.png'
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '16x16',
      href: '/favicons/Articles-16x16.png'
    }
  ],
  [ROUTE_NAME.NEWSLETTER]: () => [
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '32x32',
      href: '/favicons/Newsletter-32x32.png'
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '16x16',
      href: '/favicons/Newsletter-16x16.png'
    }
  ],
  [ROUTE_NAME.NEWSLETTER_UNSUBSCRIBE]: () => [
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '32x32',
      href: '/favicons/Newsletter-32x32.png'
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '16x16',
      href: '/favicons/Newsletter-16x16.png'
    }
  ],
  [ROUTE_NAME.CATEGORY]: routeParams =>
    FAVICON_BY_CATEGORY[routeParams.category?.toLowerCase()]
}
