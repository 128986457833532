
import color from '~/assets/styles/color.scss'

const DEFAULT_COLOR_SETTINGS_BY_VALUE = value => {
  if (value) {
    return {
      color: 'green'
    }
  }

  return {
    color: color.redWCAG
  }
}

export default {
  name: 'AStatusText',
  props: {
    value: {
      type: [Object, String, Boolean, Number, null],
      required: false,
      default: ''
    },

    /**
     * Depending on the boolean value, returns settings object
     * @param value {boolean}
     *
     * @returns
     * {
     *   color: 'red',
     *   value: 'text to display'
     * }
     */
    settingsFn: {
      type: Function,
      required: false,
      default: DEFAULT_COLOR_SETTINGS_BY_VALUE
    },
    noStyle: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    currentSettings() {
      return this.settingsFn(this.value)
    },
    displayValue() {
      const { value, i18nPath } = this.currentSettings

      return i18nPath ? this.$translateOrDefault(i18nPath) : value
    },
    color() {
      return this.currentSettings.color
    },
    dynamicStyles() {
      if (this.noStyle) return {}

      return {
        color: this.color
      }
    }
  }
}
