
import AAnimatedHint from '~/components/common/AAnimatedHint/index.vue'
import { LOCALE } from '~/constants/locale'

export default {
  name: 'SidebarListItem',
  components: { AAnimatedHint },
  props: {
    item: {
      type: Object,
      required: true
    },
    translate: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      isHintActive: false
    }
  },
  computed: {
    displayValue() {
      return this.translate
        ? this.$translateOrDefault(this.item.i18nPath)
        : this.item.title
    },
    useEnglishTranslationForHint() {
      return this.$i18n.locale === LOCALE.IT
    }
  },
  methods: {
    onMouseEnter() {
      this.isHintActive = true
    },
    onMouseLeave() {
      if (this.$helper.isTouchDevice()) {
        return
      }

      this.isHintActive = false
    }
  }
}
