import {
  PHOTO_EXTENSION,
  PHOTO_SIZE,
  PHOTO_SIZE_SUFFIX_BY_SIZE
} from '../../constants/photo'

function findClosestSizeByWidth(width) {
  const sizes = Object.values(PHOTO_SIZE)
  const closestBigger = sizes.find(size => size >= width)

  if (closestBigger === undefined) {
    return PHOTO_SIZE.SIZE_2048
  }

  return PHOTO_SIZE_SUFFIX_BY_SIZE[closestBigger]
}

export function getImage(src, { modifiers }, { options } = {}) {
  const { baseUrl } = options.providers.customCdn.defaults

  const size =
    PHOTO_SIZE_SUFFIX_BY_SIZE[modifiers.size] ||
    findClosestSizeByWidth(modifiers.width || 0)

  const extension = PHOTO_EXTENSION.WEBP

  return {
    url: `${baseUrl}${src}${size}${extension}`,
    isStatic: false
  }
}
