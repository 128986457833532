import { HTML_TAG } from '~/constants/htmlTag'

export default function() {
  if (!process.client) return

  const removeInvalidLinks = nodes => {
    nodes.forEach(node => {
      if (
        node.tagName?.toLowerCase() === HTML_TAG.LINK &&
        node.getAttribute('data-invalid') === 'true'
      ) {
        node.parentNode.removeChild(node)
      }
    })
  }

  const observerCallback = mutationsList => {
    mutationsList.forEach(mutation => {
      removeInvalidLinks(mutation.addedNodes)
    })
  }

  const observer = new MutationObserver(observerCallback)

  observer.observe(document.head, { childList: true, subtree: true })
}
