import AWS from 'aws-sdk'
import parser from 'ua-parser-js'
import Cookies from 'js-cookie'
import { ulid } from 'ulid'
import { getCognitoCredentials } from '~/utils/helpers/getCognitoCredentials'
import { cleanObject } from '~/utils/helpers/cleanObject'

const region = process.env.AWS_COGNITO_REGION || 'us-east-1'

AWS.config.update({
  region
})

const INACTIVITY_TIMEOUT = 30 * 60 * 1000
const USER_ID_COOKIE_NAME = 'uidcn'
const SESSION_ID_COOKIE_NAME = 'sidcn'
const COOKIE_EXPIRATION_DAYS = 400

function getCurrentDate() {
  return new Date().toISOString()
}

class FirehoseClient {
  constructor() {
    this._credentials = null
    this.firehose = null
    this._inactivityTimer = null
  }

  async getFirehoseInstance() {
    this._credentials = this._credentials || (await getCognitoCredentials())

    this.firehose =
      this.firehose ||
      new AWS.Firehose({
        credentials: this._credentials
      })

    return this.firehose
  }

  getSessionData() {
    let userId = Cookies.get(USER_ID_COOKIE_NAME)
    let sessionId = Cookies.get(SESSION_ID_COOKIE_NAME)

    if (!userId || !sessionId) {
      userId = userId || ulid()
      sessionId = sessionId || ulid()

      Cookies.set(USER_ID_COOKIE_NAME, userId, {
        expires: COOKIE_EXPIRATION_DAYS
      })
      Cookies.set(SESSION_ID_COOKIE_NAME, sessionId, {
        expires: COOKIE_EXPIRATION_DAYS
      })
    }

    return { user_id: userId, session_id: sessionId }
  }

  async sendData(data) {
    const firehose = await this.getFirehoseInstance()

    const params = {
      DeliveryStreamName: 'telemetry_visits',
      Record: {
        Data: Buffer.from(JSON.stringify(cleanObject(data)))
      }
    }

    return new Promise((resolve, reject) => {
      firehose.putRecord(params, (err, data) => {
        if (err) {
          console.error('Error sending data to Kinesis Firehose', err)
          reject(err)
        } else {
          resolve(data)
        }
      })
    })
  }

  handleUserActivity() {
    if (!this._inactivityTimer) {
      this.startSession()
      this.resetInactivityTimer()
    } else {
      this.resetInactivityTimer()
    }
  }

  resetInactivityTimer() {
    clearTimeout(this._inactivityTimer)

    this._inactivityTimer = setTimeout(
      this.stopSession.bind(this),
      INACTIVITY_TIMEOUT
    )
  }

  resetSessionData() {
    clearTimeout(this._inactivityTimer)
    this._inactivityTimer = null
    Cookies.remove(SESSION_ID_COOKIE_NAME, { path: '/' })
  }

  async stopSession() {
    const data = {
      ...this.getSessionData(),
      event_time: getCurrentDate(),
      type: 'session_end'
    }

    this.resetSessionData()

    await this.sendData(data)
  }

  async startSession() {
    const browserData = parser(window.navigator.userAgent)
    const { width, height } = window.screen
    const timezone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone
    const { language } = navigator

    const urlParams = Object.fromEntries(
      new URLSearchParams(window.location.search)
    )

    const {
      source_medium,
      utm_source,
      utm_medium,
      utm_campaign,
      utm_term,
      utm_content
    } = urlParams

    const searchParams = Object.keys(urlParams).map(key => ({
      key,
      value: urlParams[key]
    }))

    const userData = {
      ...this.getSessionData(),
      event_time: getCurrentDate(),
      type: 'session_start',
      time_zone: timezone,
      language: language?.toLowerCase(),
      referrer: document.referrer,
      height,
      width,
      source_medium,
      system_os: browserData.os.name,
      system_version: browserData.os.version,
      browser: browserData.browser.name,
      browser_engine: browserData.engine.name,
      utm_source,
      utm_medium,
      utm_campaign,
      utm_term,
      utm_content,
      params: searchParams
    }

    await this.sendData(userData)
  }

  async trackSessions() {
    this.handleUserActivity()

    // Attach event listeners for user activities
    window.addEventListener('click', this.handleUserActivity.bind(this), true)
    window.addEventListener('beforeunload', this.stopSession.bind(this))

    this.resetInactivityTimer()
  }

  isRouteInvalid(route) {
    return !route.name && route.fullPath === '/'
  }

  async trackPageVisitEntry(route) {
    if (this.isRouteInvalid(route)) return

    const data = {
      ...this.getSessionData(),
      event_time: getCurrentDate(),
      type: 'navigation_entry',
      page: route.path
    }

    await this.sendData(data)
  }

  async trackPageVisitExit(route) {
    if (this.isRouteInvalid(route)) return

    const data = {
      ...this.getSessionData(),
      event_time: getCurrentDate(),
      type: 'navigation_exit',
      page: route.path
    }

    await this.sendData(data)
  }

  async trackLinkClick(route) {
    const data = {
      ...this.getSessionData(),
      event_time: getCurrentDate(),
      type: 'conversion',
      page: route.path,
      action: 'referral'
    }

    await this.sendData(data)
  }

  async trackNewsletterSubscribe() {
    const data = {
      ...this.getSessionData(),
      event_time: getCurrentDate(),
      type: 'conversion',
      action: 'newsletter_subscribe'
    }

    await this.sendData(data)
  }

  async trackNewsletterUnsubscribe() {
    const data = {
      ...this.getSessionData(),
      event_time: getCurrentDate(),
      type: 'conversion',
      action: 'newsletter_unsubscribe'
    }

    await this.sendData(data)
  }

  async trackRecipeIngredientsSelectionStart(route) {
    const data = {
      ...this.getSessionData(),
      event_time: getCurrentDate(),
      type: 'action',
      page: route.path,
      action: 'started_ingredient'
    }

    await this.sendData(data)
  }

  async trackRecipeIngredientsSelectionEnd(route) {
    const data = {
      ...this.getSessionData(),
      event_time: getCurrentDate(),
      type: 'action',
      page: route.path,
      action: 'finished_ingredient'
    }

    await this.sendData(data)
  }

  async trackRecipeStepsSelectionStart(route) {
    const data = {
      ...this.getSessionData(),
      event_time: getCurrentDate(),
      type: 'action',
      page: route.path,
      action: 'started_steps'
    }

    await this.sendData(data)
  }

  async trackRecipeStepsSelectionEnd(route) {
    const data = {
      ...this.getSessionData(),
      event_time: getCurrentDate(),
      type: 'action',
      page: route.path,
      action: 'finished_steps'
    }

    await this.sendData(data)
  }
}

export default new FirehoseClient()
