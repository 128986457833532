
import debounce from 'debounce'
import { mapGetters } from 'vuex'

const DEFAULT_THRESHOLD = 1000

export default {
  name: 'AInfiniteScroll',
  props: {
    threshold: {
      type: Number,
      default: DEFAULT_THRESHOLD
    }
  },
  data() {
    return {
      handleScrollDebounced: debounce(this.handleScroll, 10)
    }
  },
  computed: {
    ...mapGetters({
      scrollTop: 'scrollTop'
    })
  },
  watch: {
    scrollTop: {
      handler() {
        if (!process.client) return

        this.handleScrollDebounced()
      },
      immediate: true
    }
  },
  methods: {
    handleScroll() {
      const windowHeight = window.innerHeight
      const documentHeight = document.documentElement.scrollHeight
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop

      // TODO LOAD MORE IF THERE IS NO SCROLL ? (all items fit the screen)
      if (documentHeight - windowHeight - scrollTop <= this.threshold) {
        this.$emit('load-more')
      }
    }
  }
}
