export const LOCALE = {
  EN: 'en',
  IT: 'it',
  UA: 'ua',
  DE: 'de'
}

export const LOCALE_VALUES = Object.values(LOCALE)

export const LOCALE_SETTINGS = [
  { code: LOCALE.EN, iso: 'en-US' },
  { code: LOCALE.IT, iso: 'it-IT' },
  { code: LOCALE.UA, iso: 'uk-UA' },
  { code: LOCALE.DE, iso: 'de-DE' }
]

export const LOCALE_PREFIX_REGEXP = new RegExp(
  `${Object.values(LOCALE)
    .map(locale => `(___${locale}$)`)
    .join('|')}`
)

export const HREFLANGS_BY_LOCALE = {
  en: ['en', 'en-US', 'x-default'],
  it: ['it', 'it-IT'],
  ua: ['uk', 'uk-UA'],
  de: ['de', 'de-DE']
}
