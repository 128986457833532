// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#__nuxt .table-filters__wrapper .table-filters__selection-text{margin-right:8px}#__nuxt .table-filters__wrapper .table-filters__dropdown{padding:0}#__nuxt .table-filters__wrapper .table-filters__dropdown .v-label{color:#000}#__nuxt .table-filters__wrapper .table-filters__dropdown,#__nuxt .table-filters__wrapper .v-list-item:before{background-color:#f8f8f8}#__nuxt .table-filters__wrapper .v-item--active{background-color:#eee}#__nuxt .table-filters__wrapper .v-list-item:hover:before{background-color:#eee;opacity:1}#__nuxt .table-filters__wrapper .v-menu__content{background-color:#fff;border:1px solid #eee;border-radius:12px;box-shadow:none}#__nuxt .table-filters__wrapper .v-input--selection-controls{margin-top:0;padding-top:0}#__nuxt .table-filters__wrapper .v-list .v-list-item--active .v-icon{color:#1976d2}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"redWCAG": "#c90b0b",
	"orangeWCAG": "#b85700",
	"greenWCAG": "#088722",
	"red": "#dc583a",
	"olive": "#9d9c2e",
	"yellowMain": "#fecb3d",
	"darkYellow": "#f3b02c",
	"lightGray1": "#f8f8f8",
	"lightGray2": "#eee",
	"darkGray3": "#777",
	"white": "#fff"
};
module.exports = ___CSS_LOADER_EXPORT___;
